import React, { useContext, useEffect, useCallback, useState } from "react";
import { Flex, Button, Spacer, Text, FlatList, Image } from "native-base";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext } from "../context/AuthContext";
import { onSnapshot, collection, query, orderBy } from "firebase/firestore";

export default function MyDisqualifications(props) {
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const [accidents, setAccidents] = useState();
  const { db } = firebaseContext;

  const getMyAccidents = useCallback(async () => {
    try {
      const user = authContext.user;
      const unsub = onSnapshot(
        query(
          collection(db, "users", user.uid, "disqualifications"),
          orderBy("created")
        ),
        (aSnap) => {
          let _accidents = [];
          aSnap.forEach((doc) => {
            let _acc = doc.data();
            _acc.aid = doc.id;
            _accidents.push(_acc);
          });
          setAccidents(_accidents);
        }
      );
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getMyAccidents();
  }, [getMyAccidents]);

  return (
    <FlatList
      data={accidents}
      renderItem={({ item }) => (
        <Flex
          alignItems="top"
          direction="row"
          bg="gray.200"
          p="2"
          m="1"
          rounded="xl"
          width="lg"
          justifyContent="space-between"
        >
          {item.created?.toDate()?.toLocaleString("da-DK", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
          <Spacer />
          {item.description}
          <Spacer />
          {item.image && (
            <Image
              source={{ uri: item.image }}
              style={{ width: 200, height: 200 }}
              alt={item.image}
            />
          )}
        </Flex>
      )}
      ListEmptyComponent={
        <Text>Du har (heldigvis) ikke nogen frakendelser endnu.</Text>
      }
      keyExtractor={(item) => item?.aid}
    />
  );
}

import type { CellPlugin } from '@react-page/editor';
import React from 'react';
import { connectField } from 'uniforms';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {v4} from "uuid";


const ImageUploadField = connectField(({ value, onChange }) => {
    return (
      <div>
        <p>Artkel billede: </p>
        {value ? (
          <img style={{ width: 150 }} src={value} />
        ) : null}
  
        <input
          type="file"
          onChange={async (e) => {
            if(e.target && e.target.files && e.target.files[0]){
                const file = e.target.files[0];
                // console.log('file',file);
                const fileRef = ref(getStorage(), v4());
                const result = await uploadBytes(fileRef, file);
                const finalUrl = await getDownloadURL(fileRef);
                // console.log('finalUrl',finalUrl);
                onChange(finalUrl);
            }
          }}
        />
      </div>
    );
  });
const PreviewPlugin: CellPlugin<{
  title: string;
  published: string;
  body: string;
  imageUrl: string;
  link: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{padding:20}}>
      <img style={{ width: "100%" }} src={data.imageUrl} />
      <p style={{color:'gray', fontFamily:"Roboto", fontWeight:500, fontSize:12}}>Dato: {data.published}</p>
      <h2 style={{fontFamily:"Roboto", fontWeight:500}}>{data.title}</h2>
      <p style={{fontFamily:"Roboto", fontWeight:400}}>{data.body}</p>
      <div style={{width:120, backgroundColor:'#0BA78F',padding:10,borderRadius:10}}>
        <center><a href={data.link} style={{color:"white"}}><b>Læs mere...</b></a></center>
      </div>
    </div>
  ),
  id: 'preview-plugin',
  title: 'Preview plugin',
  description: 'Artikel preview plugin',
  version: 1,
    controls: {
    type: 'autoform',
    schema: {
        properties: {
            title: {
                type: 'string',
                default:
                'Lækker overskrift',
            },
            
            published: { type: 'string' },
            body: { type: 'string', 
                uniforms: {
                    // you can also pass other props.
                    // refer to the official uniforms documentation
                    multiline: true,
                    rows: 4,
                }, 
            },
            imageUrl: { 
                type: 'string', 
                uniforms: {
                    component: ImageUploadField,
                }
            },
            link: {
                type: 'string',
            },
        },
        required: ['title','published','body','link', 'imageUrl'],
    },
  },
};
export default PreviewPlugin;
import React from "react";
import { Badge } from "native-base";

export default function Region(props) {
  let regionText = "";
  switch (props?.region) {
    case "hovedstaden":
      regionText = "Region Hovedstaden";
      break;
    case "sjælland":
      regionText = "Region Sjælland";
      break;
    case "syd":
      regionText = "Region Syddanmark";
      break;
    case "midt":
      regionText = "Region Midtjylland";
      break;
    case "nordjylland":
    default:
      regionText = "Region Nordjylland";
      break;
  }

  return (
    <Badge m="0" p="0">
      {regionText}
    </Badge>
  );
}

// The html5-video plugin
import html5video from "@react-page/plugins-html5-video";
require("@react-page/plugins-html5-video/lib/index.css");
// The video plugin
//import video from '@react-page/plugins-video';
// import '@react-page/plugins-video/lib/index.css';
require("@react-page/editor/lib/index.css"); //lazy loading with import caused css not to be available after build
import slate from "@react-page/plugins-slate";
import { imagePlugin } from "@react-page/plugins-image";
import spacer from "@react-page/plugins-spacer";
import divider from "@react-page/plugins-divider";
import background, { ModeEnum } from "@react-page/plugins-background";
require("@react-page/plugins-slate/lib/index.css");
require("@react-page/plugins-image/lib/index.css");
require("@react-page/plugins-spacer/lib/index.css");
require("@react-page/plugins-divider/lib/index.css");
require("@react-page/plugins-background/lib/index.css");
require("../assets/editor.css");
import PreviewPlugin from "./PreviewPlugin";
import ArticleHeadPlugin from "./ArticleHeadPlugin";
import ButtonPlugin from "./ButtonPlugin";
import uuid from "uuid";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import colorSlatePlugin from "./colorSlatePlugin";

const firestoreUploadService = (uri) => (file, reportProgress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fileRef = ref(getStorage(), uuid.v4());
      const result = await uploadBytes(fileRef, file);
      const finalUrl = await getDownloadURL(fileRef);

      resolve({ url: finalUrl });
    } catch (err) {
      reject(err);
    }
  });
};

// Define which plugins we want to use.
export const cellPlugins = [
  slate((def) => ({
    ...def,
    plugins: {
      // this will pull in all predefined plugins
      ...def.plugins,
      // you can also add custom plugins. The namespace `custom` is just for organizing plugins
      custom: {
        custom1: colorSlatePlugin,
        // katex: katexSlatePlugin,
      },
    },
  })),
  // image,
  imagePlugin({ imageUpload: firestoreUploadService() }),
  background({
    imageUpload: firestoreUploadService(),
    enabledModes:
      ModeEnum.COLOR_MODE_FLAG |
      ModeEnum.IMAGE_MODE_FLAG |
      ModeEnum.GRADIENT_MODE_FLAG,
  }),
  spacer,
  divider,
  PreviewPlugin,
  ArticleHeadPlugin,
  ButtonPlugin,
  html5video,
];

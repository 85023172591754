import React, { useContext, useEffect, useState } from "react";
import {
  FlatList,
  Flex,
  Button,
  Spacer,
  Text,
  Icon,
  HStack,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import { FirebaseContext } from "../context/FirebaseContext";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import LocationDetails from "../components/LocationDetails";
import { AuthContext } from "../context/AuthContext";

export default function LocationsList(props) {
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const { userData } = authContext;
  const [locations, setLocations] = useState();
  const { db } = firebaseContext;

  const getLocations = async () => {
    try {
      const queryConstraints = [];
      if (props?.type) {
        queryConstraints.push(where("type", "array-contains", props.type));
      }
      if (props?.region) {
        queryConstraints.push(where("region", "==", props.region));
      }

      const q = query(collection(db, "locations"), ...queryConstraints);
      const unsub = onSnapshot(q, (qSnap) => {
        const _locations = [];
        qSnap.forEach((doc) => {
          let l = doc.data();
          l.lid = doc.id;
          _locations.push(l);
        });
        setLocations(_locations);
        // console.log("Locations: ", _locations);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getLocations();
  }, [props]);

  //   console.log("props", props);
  return (
    <FlatList
      data={locations}
      renderItem={({ item }) => (
        <Flex direction="row" bg="gray.200" p="2" m="1" rounded="xl">
          <LocationDetails location={item} showDetails={true} />
          <Spacer />
          {userData?.super_admin && !props.onPick && (
            <Button onPress={() => props.onEdit(item)} bgColor="primary.500">
              <Text color="white">Rediger</Text>
            </Button>
          )}
          {props.onPick && (
            <Button onPress={() => props?.onPick(item)} bgColor="primary.500">
              <Text color="white">Vælg</Text>
            </Button>
          )}
        </Flex>
      )}
      keyExtractor={(item) => item.lid}
      ListEmptyComponent={
        <HStack space={2}>
          <Icon
            as={Entypo}
            name="warning"
            size="5"
            mt="0.5"
            color="danger.500"
          />
          <Text bold>Ingen relevante steder</Text>
        </HStack>
      }
    />
  );
}

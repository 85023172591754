import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  Center,
  Heading,
  Column,
  Text,
  FlatList,
  Divider,
  Button,
  Box,
  FormControl,
  Input,
  Select,
  Icon,
  IconButton,
  HStack,
  TextArea,
  Stack,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import {
  onSnapshot,
  doc,
  collection,
  query,
  Timestamp,
  where,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import LocationDetails from "../components/LocationDetails";
import CourseType from "../components/CourseType";
import ConfirmEnrolment from "../components/ConfirmEnrolment";
import Region from "../components/Region";
import DateTimePicker from "../components/DateTimePicker";
import TeachersList from "../components/TeachersList";
import LocationsList from "../components/LocationsList";
import { lcDate } from "../utils/tools";
import Footer from "../components/Footer";

var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

export default function CoursesScreen({ navigation }) {
  const authContext = useContext(AuthContext);
  const firebaseContext = useContext(FirebaseContext);
  const [courses, setCourses] = useState();
  const [enrolment, setEnrolment] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const toggleShowEditor = () => setShowEditor(!showEditor);
  const [showTeachers, setShowTeachers] = useState(true);
  const [pickedTeacher, setPickedTeacher] = useState(null);
  const [showLocations, setShowLocations] = useState(true);
  const [pickedLocation, setPickedLocation] = useState(null);
  const [editCourse, setEditCourse] = useState(null);
  const { db } = firebaseContext;
  const { userData } = authContext;

  const getCourses = useCallback(async () => {
    try {
      const queryConstraints = [];
      if (!userData?.super_admin) {
        queryConstraints.push(
          where("signupDeadline", ">", Timestamp.fromDate(new Date()))
        );
      }

      const q = query(collection(db, "courses"), ...queryConstraints);
      const unsub = onSnapshot(q, (qSnap) => {
        const _courses = [];
        qSnap.forEach((doc) => {
          let c = doc.data();
          c.cid = doc.id;
          _courses.push(c);
        });
        setCourses(_courses);
        // console.log("Current courses: ", _courses);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const getMyEnrolment = useCallback(async () => {
    try {
      const user = authContext.user;
      const unsub = onSnapshot(
        collection(db, "users", user.uid, "enrolment"),
        (eSnap) => {
          let _enrolment = [];
          eSnap.forEach((doc) => {
            let _enr = doc.data();
            _enr.eid = doc.id;
            _enrolment.push(_enr);
          });
          setEnrolment(_enrolment);
        }
      );
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getMyEnrolment();
  }, [getMyEnrolment]);

  const enroll = async (courseId) => {
    try {
      const enrolledCourse = courses.find((obj) => {
        return obj.cid === courseId;
      });
      await setDoc(
        doc(db, "courses", courseId, "enrolment", authContext.user.uid),
        {
          company: authContext.userData.companyId,
          email: authContext.user.email,
          created: serverTimestamp(),
        }
      );
      await setDoc(
        doc(
          db,
          "companies",
          authContext.userData.companyId,
          "enrolment",
          courseId
        ),
        {
          course_name: enrolledCourse.name,
          course_location_name: enrolledCourse.locationName,
          course_location: enrolledCourse.location,
          course_type: enrolledCourse.type,
          course_begin: enrolledCourse.begin,
          updated: serverTimestamp(),
        }
      );
      await setDoc(
        doc(
          db,
          "companies",
          authContext.userData.companyId,
          "enrolment",
          courseId,
          "enrolment",
          authContext.user.uid
        ),
        {
          course: courseId,
          email: authContext.user.email,
          first_name: authContext.userData.first_name,
          last_name: authContext.userData.last_name,
          approved: false,
          created: serverTimestamp(),
        }
      );
      await setDoc(
        doc(db, "users", authContext.user.uid, "enrolment", courseId),
        {
          course_name: enrolledCourse.name,
          course_location_name: enrolledCourse.locationName,
          course_location: enrolledCourse.location,
          course_type: enrolledCourse.type,
          course_begin: enrolledCourse.begin,
          approved: false,
          created: serverTimestamp(),
        }
      );
    } catch (error) {
      alert(error);
    }
  };

  const teacherPicked = (teacher) => {
    // console.log("teacherPicked", teacher);
    setPickedTeacher(teacher);
    setShowTeachers(false);
  };

  const locationPicked = (location) => {
    // console.log("locationPicked", location);
    setPickedLocation(location);
    setShowLocations(false);
  };

  const storeCourse = async (data) => {
    // console.log("submiting with ", data, pickedTeacher, pickedLocation);
    const dataObj = {
      name: data.name,
      location: pickedLocation.lid,
      locationName: pickedLocation.name,
      region: data.region,
      teacher: pickedTeacher.tid,
      teacherName: pickedTeacher.name,
      begin: new Date(data.begin),
      end: new Date(data.end),
      signupDeadline: new Date(data.signupDeadline),
      type: data.type,
      enrolmentCount: 0,
      capacity: data.capacity,
      created: serverTimestamp(),
      description: data.description,
    };
    // console.log("submiting ", dataObj);
    if (data.cid) {
      delete dataObj.created;
      delete dataObj.enrolmentCount;
      dataObj.updated = serverTimestamp();
      await setDoc(doc(db, "courses", data.cid), dataObj, { merge: true });
    } else {
      await addDoc(collection(db, "courses"), dataObj);
    }
    toggleShowEditor();
    setEditCourse(null);
  };
  const doEditCourse = (item) => {
    // console.log("doEditCourse", item);
    setEditCourse(item);
    setPickedTeacher({ tid: item.teacher, name: item.teacherName });
    setShowTeachers(false);
    setPickedLocation({ lid: item.location, name: item.locationName });
    setShowLocations(false);
    toggleShowEditor();
  };

  var now = new Date(Date.now() - tzoffset);
  var begin = new Date(Date.now() - tzoffset);
  var end = new Date(Date.now() - tzoffset);
  var signupDeadline = new Date(Date.now() - tzoffset);
  const initialValues = __DEV__
    ? {
        name: "Førstehjælp for alle",
        location: "gRSXCOjw2ngqKpKXV1Ke",
        locationName: "Jyllandsringen",
        capacity: 20,
        region: "nord",
        teacher: "evmt6x78DatriAeXVbfa",
        teacherName: "Søren Nissum",
        type: "first_aid",
        begin: new Date(begin.setDate(now.getDate() + 60)),
        end: new Date(end.setDate(now.getDate() + 61)),
        signupDeadline: new Date(signupDeadline.setDate(now.getDate() + 46)),
        description: "Grundig gennemgang af almen førstehjælp",
      }
    : {
        name: "",
        location: "",
        locationName: "",
        capacity: 20,
        region: "nord",
        teacher: "",
        teacherName: "",
        type: "first_aid",
        begin: new Date(begin.setDate(now.getDate() + 60)),
        end: new Date(end.setDate(now.getDate() + 61)),
        signupDeadline: new Date(signupDeadline.setDate(now.getDate() + 46)),
        description: "",
      };

  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Kurser</Heading>
      {userData?.super_admin && (
        <Center>
          {showEditor ? (
            <Formik
              initialValues={initialValues}
              onSubmit={storeCourse}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Krævet"),
                region: Yup.string().required("Krævet"),
                type: Yup.string().required("Krævet"),
                capacity: Yup.number()
                  .positive()
                  .integer()
                  .moreThan(5, "Kurser skal have over 5 pladser")
                  .lessThan(50, "Kurser skal have under 50 pladser")
                  .required("Krævet"),
                begin: Yup.string().required("Krævet"),
                end: Yup.string().required("Krævet"),
                signupDeadline: Yup.string().required("Krævet"),
                description: Yup.string(),
              })}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isValid,
              }) => {
                useEffect(() => {
                  // console.log("useEffect editCourse", editCourse);
                  // console.log("useEffect values", values);
                  const fields = [
                    "cid",
                    "name",
                    "region",
                    "type",
                    "capacity",
                    "begin",
                    "end",
                    "signupDeadline",
                    "capacity",
                    "description",
                  ];
                  if (editCourse) {
                    fields.forEach((field) => {
                      if (["begin", "end", "signupDeadline"].includes(field)) {
                        // console.log("found a date:", field, editCourse[field]);
                        setFieldValue(
                          field,
                          editCourse[field].seconds * 1000,
                          false
                        );
                      } else {
                        setFieldValue(field, editCourse[field], false);
                      }
                    });
                  }
                }, []);
                return (
                  <Box shadow={1} p={4}>
                    <Heading size="sm">
                      {editCourse ? <>Rediger kursus</> : <>Opret ny kursus</>}
                    </Heading>
                    <FormControl isRequired isInvalid={"name" in errors}>
                      <FormControl.Label>Navn</FormControl.Label>
                      <Input
                        onBlur={handleBlur("name")}
                        placeholder="Kursets navn"
                        onChangeText={handleChange("name")}
                        value={values.name}
                      />
                      <FormControl.ErrorMessage>
                        {errors.name}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={"description" in errors}>
                      <FormControl.Label>Beskrivelse</FormControl.Label>
                      <TextArea
                        onBlur={handleBlur("description")}
                        placeholder="Beskrivelse af kurset..."
                        onChange={handleChange("description")}
                        value={values.description}
                      />
                      <FormControl.ErrorMessage>
                        {errors.description}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"region" in errors}>
                      <FormControl.Label>Region</FormControl.Label>
                      <Select
                        defaultValue={values.region}
                        accessibilityLabel="Vælg region"
                        placeholder="Vælg region"
                        mt={1}
                        selectedValue={values.region}
                        onValueChange={handleChange("region")}
                        onBlur={handleBlur("region")}
                        style={{ display: "block" }}
                      >
                        <Select.Item label="Nordjylland" value="nord" />
                        <Select.Item label="Midtjylland" value="midt" />
                        <Select.Item label="Syddanmark" value="syd" />
                        <Select.Item label="Hovedstaden" value="hovedstaden" />
                        <Select.Item label="Sjælland" value="sjælland" />
                      </Select>
                      <FormControl.ErrorMessage>
                        {errors.region}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"type" in errors}>
                      <FormControl.Label>Type</FormControl.Label>
                      <Select
                        defaultValue={values.type}
                        accessibilityLabel="Vælg type"
                        placeholder="Vælg type"
                        mt={1}
                        selectedValue={values.type}
                        onValueChange={handleChange("type")}
                        onBlur={handleBlur("type")}
                        style={{ display: "block" }}
                      >
                        <Select.Item
                          label="Køreteknisk kursus"
                          value="driving_technique"
                        />
                        <Select.Item
                          label="Førstehjælpskursus"
                          value="first_aid"
                        />
                        <Select.Item
                          label="Glatførekursus"
                          value="ice_driving"
                        />
                        <Select.Item label="Grønt kørekursus" value="green" />
                      </Select>
                      <FormControl.ErrorMessage>
                        {errors.type}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={pickedTeacher == null}>
                      {showTeachers ? (
                        <>
                          <FormControl.Label>Vælg underviser</FormControl.Label>
                          <TeachersList
                            region={values.region}
                            type={values.type}
                            onPick={teacherPicked}
                          />
                        </>
                      ) : (
                        <>
                          <Text>
                            Valgt underviser: {pickedTeacher.name}{" "}
                            <IconButton
                              icon={
                                <Icon
                                  as={Entypo}
                                  name="circle-with-cross"
                                  color="primary.500"
                                />
                              }
                              onPress={() => {
                                setPickedTeacher(null);
                                setShowTeachers(true);
                              }}
                            />
                          </Text>
                        </>
                      )}
                      <FormControl.ErrorMessage>
                        {errors.teacher}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={pickedLocation === null}>
                      {showLocations ? (
                        <>
                          <FormControl.Label>
                            Vælg undervisningsted
                          </FormControl.Label>
                          <LocationsList
                            region={values.region}
                            type={values.type}
                            onPick={locationPicked}
                          />
                        </>
                      ) : (
                        <>
                          <Text>
                            Valgt sted: {pickedLocation.name}{" "}
                            <IconButton
                              icon={
                                <Icon
                                  as={Entypo}
                                  name="circle-with-cross"
                                  color="primary.500"
                                />
                              }
                              onPress={() => {
                                setPickedLocation(null);
                                setShowLocations(true);
                              }}
                            />
                          </Text>
                        </>
                      )}
                    </FormControl>

                    <FormControl isRequired isInvalid={"capacity" in errors}>
                      <FormControl.Label>Antal pladser</FormControl.Label>
                      <Input
                        onBlur={handleBlur("capacity")}
                        placeholder="Antal pladser på kurset"
                        onChangeText={handleChange("capacity")}
                        value={values.capacity}
                      />
                      <FormControl.ErrorMessage>
                        {errors.capacity}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"begin" in errors}>
                      <FormControl.Label>Starttidspunkt</FormControl.Label>
                      <DateTimePicker
                        value={new Date(values.begin)
                          .toISOString()
                          .slice(0, -8)}
                        onChange={handleChange("begin")}
                      />
                    </FormControl>

                    <FormControl isRequired isInvalid={"end" in errors}>
                      <FormControl.Label>Sluttidspunkt</FormControl.Label>
                      <DateTimePicker
                        value={new Date(values.end).toISOString().slice(0, -8)}
                        onChange={handleChange("end")}
                      />
                    </FormControl>

                    <FormControl
                      isRequired
                      isInvalid={"signupDeadline" in errors}
                    >
                      <FormControl.Label>Tilmeldingsfrist</FormControl.Label>
                      <DateTimePicker
                        value={new Date(values.signupDeadline)
                          .toISOString()
                          .slice(0, -8)}
                        onChange={handleChange("signupDeadline")}
                      />
                    </FormControl>

                    <Button
                      isDisabled={!isValid || !pickedLocation || !pickedTeacher}
                      onPress={handleSubmit}
                      bgColor="primary.500"
                    >
                      {editCourse ? (
                        <Text color="white">Gem kursus</Text>
                      ) : (
                        <Text color="white">Opret kursus</Text>
                      )}
                    </Button>
                    <Button
                      onPress={() => {
                        setShowEditor(false);
                        setEditCourse(null);
                        setPickedLocation(null);
                        setPickedTeacher(null);
                        setShowLocations(true);
                        setShowTeachers(true);
                      }}
                      variant="outline"
                    >
                      <Text>Afbryd</Text>
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          ) : (
            <Button
              bg="primary.500"
              key="createBtn"
              onPress={() => {
                setEditCourse(null);
                toggleShowEditor();
              }}
            >
              <Text color="white">Opret nyt kursus</Text>
            </Button>
          )}
        </Center>
      )}
      <FlatList
        data={courses}
        renderItem={({ item }) => (
          <Box p={5} rounded="xl" shadow={4} w="100%" bg="gray.200">
            <Stack space={6}>
              <Stack space={3}>
                <Heading size="lg" color="primary.500">
                  {item.name} <Region region={item?.region} />
                </Heading>
                <Divider my="2" />
                <Text fontSize="s">{item.description}</Text>
              </Stack>
              <HStack
                justifyContent="space-between"
                alignItems="flex-end"
                flexShrink={1}
              >
                <Stack space={3}>
                  <HStack space={3} alignItems="center" flexShrink={1}>
                    <Icon name="grid" as={Entypo} color="primary.500" />
                    <Text flexShrink={1} color="text.500">
                      <>
                        <CourseType type={item.type} /> ved {item.teacherName}
                      </>
                    </Text>
                  </HStack>
                  <HStack space={3} alignItems="center" flexShrink={1}>
                    <Icon name="location" as={Entypo} color="primary.500" />
                    <Text flexShrink={1}>
                      <LocationDetails
                        locationId={item?.location}
                        showDetails={true}
                      />
                    </Text>
                  </HStack>
                  <HStack space={3} alignItems="center">
                    <Icon name="calendar" as={Entypo} color="primary.500" />
                    <Text>
                      Dato: {lcDate(item.begin?.toDate())}-
                      {lcDate(item.end?.toDate())}
                    </Text>
                  </HStack>
                  <HStack space={3} alignItems="center">
                    <Icon name="calendar" as={Entypo} color="primary.500" />
                    <Text>
                      Tilmeldingsfrist: {lcDate(item.signupDeadline?.toDate())}
                    </Text>
                  </HStack>
                  <HStack space={3} alignItems="center">
                    <Icon name="users" as={Entypo} color="primary.500" />
                    {userData?.super_admin ? (
                      <Text>
                        Tilmeldte: {item?.enrolmentCount} af {item?.capacity}{" "}
                        pladser
                      </Text>
                    ) : (
                      <Text>
                        {item?.capacity - item?.enrolmentCount} ledige pladser
                      </Text>
                    )}
                  </HStack>
                  <HStack space={3} alignItems="center">
                    {userData?.super_admin ? (
                      <Button
                        onPress={() => doEditCourse(item)}
                        bgColor="primary.500"
                      >
                        <Text color="white">Rediger</Text>
                      </Button>
                    ) : (
                      <>
                        {enrolment?.find((e) => e.eid == item.cid) ? (
                          <Text>Tilmeldt</Text>
                        ) : (
                          <>
                            {userData?.companyActive &&
                              item?.enrolmentCount < item?.capacity && (
                                <ConfirmEnrolment
                                  onConfirm={() => enroll(item.cid)}
                                >
                                  Tilmeld
                                </ConfirmEnrolment>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </HStack>
                </Stack>
              </HStack>
            </Stack>
          </Box>
        )}
        keyExtractor={(item) => item?.cid}
        ListEmptyComponent={
          <HStack space={2}>
            <Icon
              as={Entypo}
              name="warning"
              size="5"
              mt="0.5"
              color="danger.500"
            />
            <Text bold>
              Her kan du se alle kommende kurser og tilmelde dig. Bemærk, er der
              ingen kurser at vælge, er der blot ingen kurser på nuværende
              tidspunkt i din region.
            </Text>
          </HStack>
        }
      />
      <Footer />
    </Column>
  );
}

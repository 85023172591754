import React, { useContext, useEffect, useCallback, useState } from "react";
import { FlatList, Button, Spacer, Text, Icon, HStack } from "native-base";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import {
  onSnapshot,
  collection,
  query,
  where,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import Region from "./Region";
import { Entypo } from "@expo/vector-icons";
import { Link as NavLink } from "@react-navigation/native";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

export default function CompaniesList(props) {
  const authContext = useContext(AuthContext);
  const firebaseContext = useContext(FirebaseContext);
  const [companies, setCompanies] = useState();
  const { db } = firebaseContext;
  const { userData } = authContext;

  const delCompany = async (cid) => {
    console.log("☠️☠️☠️ delCompany...", cid);
    await updateDoc(
      doc(db, "companies", cid),
      { deleted: true, deletedTime: serverTimestamp() },
      { merge: true }
    );
  };

  const getCompanies = async () => {
    try {
      const queryConstraints = [];
      if (props?.active) {
        queryConstraints.push(where("active", "==", props.active));
      }
      if (props?.region) {
        queryConstraints.push(where("region", "==", props.region));
      }
      const q = query(collection(db, "companies"), ...queryConstraints);
      const unsub = onSnapshot(q, (qSnap) => {
        const _companies = [];
        qSnap.forEach((doc) => {
          let c = doc.data();
          c.cid = doc.id;
          if (!c.deleted) _companies.push(c);
        });

        setCompanies(_companies);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    // console.log("companylist props change", props);
    getCompanies();
  }, [props]);

  return (
    <FlatList
      data={companies}
      renderItem={({ item }) => (
        <HStack
          w="xl"
          justifyContent="space-between"
          alignItems="center"
          bg="gray.200"
          p="2"
          m="1"
        >
          {item.active ? (
            <Icon
              as={Entypo}
              name="check"
              size="5"
              mt="0.5"
              color="success.500"
            />
          ) : (
            <Icon
              as={Entypo}
              name="warning"
              size="5"
              mt="0.5"
              color="danger.500"
            />
          )}
          <Spacer />
          <Text>{item.name}</Text>
          <Spacer />
          <Text>{item.address}</Text>
          <Spacer />
          <Text>
            {item.zip} {item.city}
          </Text>
          <Spacer />
          {props.showRegion && <Region region={item.region} />}
          <Spacer />
          <Text>invitationer: {item.inviteCount}</Text>
          <Spacer />
          <Text>brugere: {item.userCount}</Text>
          <Spacer />
          <NavLink to={`/company?companyId=${item.cid}`}>
            <Button bgColor="primary.500" mx="1">
              <Text color="white">Vis</Text>
            </Button>
          </NavLink>
          <NavLink to={`/employees?companyId=${item.cid}`}>
            <Button bgColor="primary.500" mx="1 ">
              <Text color="white">Medarbejdere</Text>
            </Button>
          </NavLink>
          <ConfirmDeleteDialog
            header="Slet virksomhed"
            onConfirmDelete={() => delCompany(item.cid)}
          />
        </HStack>
      )}
      keyExtractor={(item) => item.cid}
      ListEmptyComponent={
        <HStack space={2}>
          <Icon
            as={Entypo}
            name="warning"
            size="5"
            mt="0.5"
            color="danger.500"
          />
          <Text bold>Ingen virksomheder</Text>
        </HStack>
      }
    />
  );
}

import React, { useContext, useEffect, useCallback, useState } from "react";
import { Heading, Column, Text, Icon, HStack, Button } from "native-base";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import { getDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import Region from "../components/Region";
import { Entypo } from "@expo/vector-icons";
import Footer from "../components/Footer";

export default function CompanyScreen({ navigation, route }) {
  const authContext = useContext(AuthContext);
  const { user, userData } = authContext;
  const firebaseContext = useContext(FirebaseContext);
  const [company, setCompany] = useState();
  const { db } = firebaseContext;

  const getCompany = useCallback(async () => {
    try {
      const cSnap = await getDoc(
        doc(
          db,
          "companies",
          route?.params?.companyId || authContext.userData.companyId
        )
      );
      if (cSnap.exists()) {
        let comp = cSnap.data();
        comp.cid = cSnap.id;
        console.log("company", comp);
        setCompany(comp);
      }
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  const activateCompany = async (cid) => {
    await setDoc(
      doc(db, "companies", cid),
      {
        active: true,
        activated: serverTimestamp(),
        activatedBy: user.uid,
      },
      { merge: true }
    );
    await getCompany();
  };
  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">
        {company?.name || "DIT firma"} hos LicenseCare
      </Heading>
      {company?.active ? (
        <HStack space={2}>
          <Text>Firmaet er aktivt.</Text>
          <Icon
            as={Entypo}
            name="check"
            size="5"
            mt="0.5"
            color="success.500"
          />
        </HStack>
      ) : (
        <HStack space={2}>
          <Text bold>
            Firma er endnu ikke aktivt, invitér mindst 9 medarbejdere for at
            aktivere.
          </Text>
          <Icon
            as={Entypo}
            name="warning"
            size="5"
            mt="0.5"
            color="danger.500"
          />
          {user && userData?.super_admin && (
            <Button
              bgColor="primary.500"
              onPress={() => activateCompany(company.cid)}
              ml={3}
            >
              <Text color="white">Aktiver</Text>
            </Button>
          )}
        </HStack>
      )}
      <Text>{company?.name}</Text>
      <Text>CVR {company?.cvr}</Text>
      <Text>{company?.address}</Text>
      <Text>
        {company?.zip} {company?.city}
      </Text>
      <Region region={company?.region} />
      <Text>{company?.invoice_mail}</Text>
      <Text>{company?.phone}</Text>
      <Text>
        Oprettet{" "}
        {company?.created?.toDate().toLocaleString("da-DK", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Text>
      <Text>Antal brugere: {company?.userCount}</Text>
      <Text>Antal åbne invitationer: {company?.inviteCount}</Text>
      {/* //TODO: implement ret stamdata
      {authContext?.userData?.company_admin && (
        <Button>Rediger</Button>
      )}
      {authContext?.userData?.company_admin && (
        <Button disabled bg="danger.500">
          Opsig aftale
        </Button>
      )} */}
      <Footer />
    </Column>
  );
}

import React from "react";
import { NativeBaseProvider, extendTheme } from "native-base";
import { FirebaseProvider } from "./context/FirebaseContext";
import { AuthProvider } from "./context/AuthContext";
import AppNavigation from "./Navigation";
import {
  useFonts,
  Roboto_300Light,
  Roboto_500Medium,
  Roboto_700Bold,
} from "@expo-google-fonts/roboto";

const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      200: "#11CEB1",
      500: "#0BA78F",
    },
    secondary: {
      500: "#003E32",
    },
    text: {
      500: "#7A7A7A",
    },
    success: {
      500: "#34FB34",
    },
    danger: {
      500: "#cf2e2e",
    },
    error: {
      500: "#cf2e2e",
    },
    warning: {
      500: "#fcb900",
    },
  },
  fontConfig: {
    Roboto: {
      100: {
        normal: "Roboto_300Light",
      },
      200: {
        normal: "Roboto_300Light",
      },
      300: {
        normal: "Roboto_300Light",
      },
      400: {
        normal: "Roboto_500Medium",
      },
      500: {
        normal: "Roboto_500Medium",
      },
      600: {
        normal: "Roboto_500Medium",
      },
      700: {
        normal: "Roboto_700Bold",
      },
      800: {
        normal: "Roboto_700Bold",
      },
      900: {
        normal: "Roboto_700Bold",
      },
    },
  },
  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: "Roboto",
    body: "Roboto",
    mono: "Roboto",
  },
  components: {
    Text: {
      // Can simply pass default props to change default behaviour of components.
      baseStyle: {
        fontFamily: "body",
        fontWeight: "400",
      },
    },
    Heading: {
      // Can simply pass default props to change default behaviour of components.
      baseStyle: {
        fontFamily: "heading",
        fontWeight: "700",
      },
    },
  },
});

export default function App() {
  let [fontsLoaded] = useFonts({
    Roboto_300Light,
    Roboto_500Medium,
    Roboto_700Bold,
  });

  if (!fontsLoaded) {
    return null;
  }
  return (
    <NativeBaseProvider theme={theme}>
      <FirebaseProvider>
        <AuthProvider>
          <AppNavigation />
        </AuthProvider>
      </FirebaseProvider>
    </NativeBaseProvider>
  );
}

import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Center,
  Heading,
  Column,
  Text,
  Button,
  FlatList,
  FormControl,
  Input,
  Flex,
  Spacer,
  Box,
  Badge,
  HStack,
  Icon,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import {
  getDoc,
  getDocs,
  collection,
  doc,
  addDoc,
  serverTimestamp,
  orderBy,
  query,
  deleteDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";
import Region from "../components/Region";
import { Entypo } from "@expo/vector-icons";
import Footer from "../components/Footer";

export default function EmployeesScreen({ navigation, route }) {
  const authContext = useContext(AuthContext);
  const [company, setCompany] = useState();
  const [companyEmployees, setCompanyEmployees] = useState([]);
  const [companyEmployeeInvites, setCompanyEmployeeInvites] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const toggleShowAdd = () => setShowAdd(!showAdd);
  const firebaseContext = useContext(FirebaseContext);
  const { db } = firebaseContext;

  const getCompany = useCallback(async () => {
    try {
      const cid = route?.params?.companyId || authContext?.userData?.companyId;
      const q = query(doc(db, "companies", cid));
      const unsub = onSnapshot(q, (cSnap) => {
        if (cSnap.exists()) {
          let comp = cSnap.data();
          comp.companyId = cid;
          setCompany(comp);
        }
      });
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  const getCompanyEmployees = useCallback(async () => {
    try {
      const ceSnapshot = await getDocs(
        query(
          collection(
            db,
            "companies",
            route?.params?.companyId || authContext.userData.companyId,
            "users"
          ),
          orderBy("created")
        )
      );
      let emps = [];
      ceSnapshot.forEach((doc) => {
        // console.log(`${doc.id} => ${doc.data()}`);
        let emp = doc.data();
        emp.uid = doc.id;
        emps.push(emp);
      });

      // console.log("emps", emps);
      setCompanyEmployees(emps);
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCompanyEmployees();
  }, [getCompanyEmployees]);

  const getCompanyEmployeeInvites = useCallback(async () => {
    try {
      const ceSnapshot = await getDocs(
        query(
          collection(
            db,
            "companies",
            route?.params?.companyId || authContext.userData.companyId,
            "invites"
          ),
          orderBy("invited")
        )
      );
      let invites = [];
      ceSnapshot.forEach((doc) => {
        // console.log(`${doc.id} => ${doc.data()}`);
        let inv = doc.data();
        inv.iid = doc.id;
        invites.push(inv);
      });

      // console.log("invites", invites);
      setCompanyEmployeeInvites(invites);
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCompanyEmployeeInvites();
  }, [getCompanyEmployeeInvites]);

  const delUser = async (uid) => {
    console.log("☠️☠️☠️ deleting user ", uid);
    await updateDoc(
      doc(
        db,
        "companies",
        route?.params?.companyId || authContext.userData.companyId,
        "users",
        uid
      ),
      { deleted: true, deletedTime: serverTimestamp() },
      { merge: true }
    );
    // TODO: actually delete user with a cloud function
    // TODO: delete data from /users/uid is handled with extension
    // TODO: delete data from /companies/c123/users/uid with extension?
    getCompanyEmployees();
  };

  const delInvite = async (iid) => {
    // console.log("deleting invite ", iid);
    await deleteDoc(doc(db, "companies", company.companyId, "invites", iid));
    getCompanyEmployeeInvites();
  };

  const addInvite = async (data) => {
    // console.log("submiting with ", data, company);

    //  √ create /companies/c123/invites in db
    //  √ cloud function to pick up invites and send email (ala pintrip)
    //  √ send invites with accept link
    //  √ accept links to signup form for a user tied to a company
    //  √ check if invite still exists

    await addDoc(collection(db, "companies", company.companyId, "invites"), {
      email: data.email,
      company_name: company.name,
      invited: serverTimestamp(),
    });
    toggleShowAdd();
    getCompanyEmployeeInvites();
  };

  const resendInvite = async (iid) => {
    const invitesCollection = collection(
      db,
      "companies",
      company.companyId,
      "invites"
    );
    const oldInviteDoc = doc(invitesCollection, iid);

    const oiSnap = await getDoc(oldInviteDoc);
    if (oiSnap.exists()) {
      const oldInviteData = oiSnap.data();
      console.log("oldInviteData", oldInviteData);
      await deleteDoc(oldInviteDoc);
      await addDoc(invitesCollection, {
        email: oldInviteData.email,
        company_name: company.name,
        invited: serverTimestamp(),
      });
    }

    getCompanyEmployeeInvites();
  };

  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Administrer medarbejdere</Heading>
      <Text>
        Her kan du tilføje, rette og slette medarbejdere til{" "}
        {company?.name || "din "}'s virksomhedsaftale.
      </Text>
      {company?.active ? (
        <HStack space={2}>
          <Text>Firmaet er aktivt.</Text>
          <Icon
            as={Entypo}
            name="check"
            size="5"
            mt="0.5"
            color="success.500"
          />
        </HStack>
      ) : (
        <HStack space={2}>
          <Text bold>
            Firma er endnu ikke aktivt, invitér mindst 9 medarbejdere for at
            aktivere.
          </Text>
          <Icon
            as={Entypo}
            name="warning"
            size="5"
            mt="0.5"
            color="danger.500"
          />
        </HStack>
      )}
      <FlatList
        width="80%"
        data={companyEmployees}
        renderItem={({ item, index }) => {
          if (item?.deleted) return;
          return (
            <Flex
              direction="row"
              alignItems="center"
              bg="gray.200"
              p="2"
              m="1"
              rounded="lg"
              width="100%"
              justifyContent="space-between"
              key={item.cid}
            >
              {item.company_admin ? (
                <>
                  {item.uid === authContext.user.uid ? (
                    <Text>Admin {item.email} (YOU)</Text>
                  ) : (
                    <Text>Admin {item.email}</Text>
                  )}
                </>
              ) : (
                <Text>
                  User {index + 1} {item.email}
                </Text>
              )}
              <Spacer />
              {item?.license_categories?.map((c, i) => (
                <Badge
                  alignItems="center"
                  colorScheme="danger"
                  ml={1}
                  rounded="md"
                  key={i}
                >
                  {c}
                </Badge>
              ))}
              {item?.region && <Region region={item.region} />}
              <Spacer />
              Oprettet:{" "}
              {new Date(item.created.seconds * 1000)
                .toISOString()
                .slice(0, -14)}
              <Spacer />
              {/* //TODO: implement
               <Button>Rediger</Button> */}
              {!item.company_admin && (
                <ConfirmDeleteDialog
                  header="Slet bruger"
                  onConfirmDelete={() => delUser(item.uid)}
                />
              )}
            </Flex>
          );
        }}
        keyExtractor={(item) => item?.email}
      />
      <Heading size="md">Invitationer</Heading>
      <Text>
        Her kan du som virksomhed invitere dine medarbejdere med adgang til
        LicenseCare abonnement.
      </Text>
      <Text>Den enkelte medarbejder vil modtage en mail herom. </Text>
      {showAdd ? (
        <Formik
          initialValues={{
            email: route?.params?.email || "",
          }}
          onSubmit={addInvite}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Ikke en email adresse")
              .required("Krævet"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit: handleSubmit,
            values,
            errors,
            isValid,
          }) => (
            <Center>
              <Column>
                <Box shadow={1} p={4}>
                  <Heading size="sm">Invitér Medarbejder</Heading>
                  <FormControl isRequired isInvalid={"email" in errors}>
                    <FormControl.Label>Email</FormControl.Label>
                    <Input
                      onBlur={handleBlur("email")}
                      placeholder="navn@firma.dk"
                      onChangeText={handleChange("email")}
                      value={values.email}
                    />
                    <FormControl.ErrorMessage>
                      {errors.email}
                    </FormControl.ErrorMessage>
                  </FormControl>

                  <Button
                    isDisabled={!isValid}
                    onPress={handleSubmit}
                    bgColor="primary.500"
                  >
                    Invitér medarbejder
                  </Button>
                  <Button variant="outline" onPress={toggleShowAdd}>
                    <Text>Afbryd</Text>
                  </Button>
                </Box>
              </Column>
            </Center>
          )}
        </Formik>
      ) : (
        <Button onPress={toggleShowAdd} bgColor="primary.500">
          <Text color="white">Invitér medarbejder</Text>
        </Button>
      )}
      <FlatList
        width="80%"
        data={companyEmployeeInvites}
        renderItem={({ item, index }) => (
          <Flex
            alignItems="center"
            direction="row"
            w="md"
            bg="gray.300"
            p="2"
            m="1"
            rounded="lg"
            width="100%"
          >
            {item.email}
            <Spacer />
            Inviteret:{" "}
            {new Date(item.invited.seconds * 1000).toISOString().slice(0, -14)}
            <Spacer />
            <Button
              mx="1"
              bg="warning.500"
              onPress={() => resendInvite(item.iid)}
            >
              <Text color="white">Gensend</Text>
            </Button>
            <Button mx="1" bg="danger.500" onPress={() => delInvite(item.iid)}>
              <Text color="white">Slet</Text>
            </Button>
          </Flex>
        )}
        keyExtractor={(item) => item?.email}
      />
      <Footer />
    </Column>
  );
}

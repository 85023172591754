import React, { useState, useContext } from "react";
import {
  Center,
  Heading,
  Column,
  Text,
  Button,
  Image,
  Box,
  FormControl,
  TextArea,
  Modal,
  Link,
} from "native-base";
import MyDisqualifications from "../components/MyDisqualifications";
import * as ImagePicker from "expo-image-picker";
import Spinner from "../components/Spinner";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import uuid from "uuid";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import TeachersList from "../components/TeachersList";
import Footer from "../components/Footer";
// import { Link } from "@react-navigation/native";

export default function DisqualificationScreen({ navigation }) {
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const toggleShowAdd = () => setShowAdd(!showAdd);
  const [showTeachers, setShowTeachers] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [pickedTeacher, setPickedTeacher] = useState(null);
  const authContext = useContext(AuthContext);
  const firebaseContext = useContext(FirebaseContext);
  const { db } = firebaseContext;

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    setUploading(true);

    console.log(result);

    if (!result.cancelled) {
      const uploadUrl = await uploadImageAsync(result.uri);
      setImage(uploadUrl);
      setUploading(false);
    }
  };

  const uploadImageAsync = async (uri) => {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuid.v4());
    const result = await uploadBytes(fileRef, blob);

    return await getDownloadURL(fileRef);
  };

  const addDisqualification = async (data) => {
    console.log("submiting with ", data, image, pickedTeacher);
    const disq = await addDoc(
      collection(db, "users", authContext.user.uid, "disqualifications"),
      {
        description: data.description,
        image: image,
        teacher_id: pickedTeacher?.tid,
        created: serverTimestamp(),
      }
    );
    await setDoc(
      doc(
        db,
        "companies",
        authContext.userData.companyId,
        "disqualifications",
        disq.id
      ),
      {
        description: data.description,
        image: image,
        user_id: authContext.user.uid,
        user_email: authContext.user.email,
        teacher_id: pickedTeacher?.tid,
        teacher_name: pickedTeacher?.name,
        created: serverTimestamp(),
      }
    );
    toggleShowAdd();
    setShowConfirm(true);
  };
  const teacherPicked = (teacher) => {
    console.log("teacherPicked", teacher);
    setPickedTeacher(teacher);
    setShowTeachers(false);
  };

  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Opret frakendelse</Heading>
      <Text>Her kan du oprette din frakendelse hurtigt og nemt.</Text>
      {showAdd ? (
        <Formik
          initialValues={{
            description: "",
          }}
          onSubmit={addDisqualification}
          validationSchema={Yup.object().shape({
            description: Yup.string().required("Krævet"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit: handleSubmit,
            values,
            errors,
            isValid,
          }) => (
            <Center>
              <Column>
                <Box shadow={1} p={4}>
                  <Heading size="sm">Opret frakendelse</Heading>
                  <FormControl isRequired isInvalid={"description" in errors}>
                    <FormControl.Label>Beskrivelse</FormControl.Label>
                    <TextArea
                      onBlur={handleBlur("description")}
                      placeholder="Beskriv hændelsen..."
                      onChange={handleChange("description")}
                      value={values.description}
                    />
                    <FormControl.ErrorMessage>
                      {errors.description}
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <Button onPress={pickImage} bgColor="primary.500">
                    <Text color="white">Tilføj billede af politirapporten</Text>
                  </Button>
                  {image && (
                    <Image
                      source={{ uri: image }}
                      style={{ width: 200, height: 200 }}
                      alt="image"
                    />
                  )}
                  {showTeachers ? (
                    <>
                      <FormControl.Label>Vælg kørerlærer</FormControl.Label>
                      <TeachersList
                        region={authContext.userData?.region}
                        type="drivers_license"
                        onPick={teacherPicked}
                      />
                    </>
                  ) : (
                    <Text>Valgt kørerlærer: {pickedTeacher.name}</Text>
                  )}
                  <Button
                    isDisabled={!isValid || !pickedTeacher || !image}
                    onPress={handleSubmit}
                    bgColor="primary.500"
                  >
                    Opret frakendelse
                  </Button>

                  <Button variant="outline" onPress={toggleShowAdd}>
                    <Text>Afbryd</Text>
                  </Button>
                </Box>
              </Column>
            </Center>
          )}
        </Formik>
      ) : (
        <>
          {uploading ? (
            <Spinner />
          ) : (
            <>
              {authContext?.userData?.companyActive && (
                <Button
                  key="createBtn"
                  onPress={toggleShowAdd}
                  bg="primary.500"
                >
                  Opret frakendelse
                </Button>
              )}
            </>
          )}
        </>
      )}
      <Modal isOpen={showConfirm}>
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton
            onPress={() => {
              setShowAdd(false);
              setShowConfirm(false);
            }}
          />
          <Modal.Header>Book din køreprøve</Modal.Header>
          <Modal.Body>
            Nu skal du bare booke tid til en køreprøve, så tager vi fat i dig.
          </Modal.Body>
          <Modal.Footer>
            <Link isExternal href="https://koreprovebooking.dk/">
              <Button bgColor="primary.500">
                <Text color="white">Book</Text>
              </Button>
            </Link>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <MyDisqualifications />
      <Footer />
    </Column>
  );
}

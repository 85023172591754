import React, { useState, useEffect, useContext } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  FormControl,
  Input,
  Text,
  Checkbox,
  Select,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext, readUserData } from "../context/AuthContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import Footer from "../components/Footer";

export default function AcceptInviteScreen({ navigation, route }) {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [invite, setInvite] = useState();
  const toggleShowPass = () => setShowPass(!showPass);
  const toggleShowPass2 = () => setShowPass2(!showPass2);
  const [licenseCategories, setLicenseCategories] = React.useState(["B"]);
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const { db } = firebaseContext;

  const onLoad = async () => {
    // console.log("onLoad route", route);
    //https://app.licensecare.dk/acceptInvite/${route.params.companyId}/${route.params.inviteId}
    try {
      const iSnap = await getDoc(
        doc(
          db,
          "companies",
          route.params.companyId,
          "invites",
          route.params.inviteId
        )
      );
      if (iSnap.exists()) {
        let _invite = iSnap.data();
        _invite.inviteId = route.params.inviteId;
        setInvite(_invite);
        console.log("found invite", _invite);
      } else {
        console.log("invite not found!!!");
        setInvite("invalid");
      }
    } catch (error) {
      console.error(error);
      setInvite("invalid");
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onSubmit = async (data) => {
    const parts = data.birthday.split("-");
    const bDate = new Date();
    bDate.setDate(parts[0]);
    bDate.setMonth(parts[1] - 1); //month are 0 indexed
    bDate.setYear(parts[2]);
    console.log("submiting with ", data, licenseCategories, bDate);

    try {
      const { auth, db } = firebaseContext;
      // console.log("onSubmit invite", invite);

      createUserWithEmailAndPassword(auth, invite.email, data.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          console.log("Signed in user", user);

          await setDoc(
            doc(db, "companies", route.params.companyId, "users", user.uid),
            {
              email: user.email,
              first_name: data.first_name,
              last_name: data.last_name,
              region: data.region,
              company_admin: false,
              created: serverTimestamp(),
              license_categories: licenseCategories,
              birthday: bDate,
              wooSubUserId: invite?.wooSubUserId ? invite.wooSubUserId : "NA",
              wooProductId: invite?.wooProductId ? invite.wooProductId : "NA",
            }
          );
          await setDoc(doc(db, "users", user.uid), {
            email: user.email,
            first_name: data.first_name,
            last_name: data.last_name,
            region: data.region,
            companyId: route.params.companyId,
            company_admin: false,
            created: serverTimestamp(),
            license_categories: licenseCategories,
            birthday: bDate,
            wooSubUserId: invite?.wooSubUserId ? invite.wooSubUserId : "NA",
            wooProductId: invite?.wooProductId ? invite.wooProductId : "NA",
          });

          const { setUserData } = authContext;
          setUserData(await readUserData(db, user.uid));

          //delete the invite
          await deleteDoc(
            doc(
              db,
              "companies",
              route.params.companyId,
              "invites",
              invite.inviteId
            )
          );
          console.log("accept invite done");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("error", errorCode, errorMessage);
        });
    } catch (error) {
      alert("Accept Invite Failed", error);
    }
  };

  const initialValues = __DEV__
    ? {
        password: "Hemmelig1",
        passwordVerify: "Hemmelig1",
        first_name: "Navn",
        last_name: "Navnesen",
        region: "nord",
        birthday: "24-12-1985",
      }
    : {
        password: "",
        passwordVerify: "",
        first_name: "",
        last_name: "",
        region: "nord",
        birthday: "",
      };
  return (
    <Center>
      {invite === "invalid" ? (
        <Column>
          <Heading>Invitation ikke fundet!</Heading>
          <Text>Kontakt din virksomhedsadministrator</Text>
        </Column>
      ) : (
        <Column>
          <Heading size="lg">
            Acceptér invitation fra {invite?.company_name}
          </Heading>
          <Text>Vi byder velkommen til LicenseCare.</Text>
          <Text>
            Opret bruger og få adgang til LicenseCare Platform som medarbejder.
          </Text>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .required("Password mangler.")
                .min(8, "Password er for kort - should be 8 chars minimum.")
                .matches(
                  /[a-zA-Z0-9]/,
                  "Password can only contain Latin letters and numbers."
                ),
              passwordVerify: Yup.string()
                .required("Password mangler.")
                .min(8, "Password er for kort - should be 8 chars minimum.")
                .matches(
                  /[a-zA-Z0-9]/,
                  "Password can only contain Latin letters and numbers."
                )
                .oneOf([Yup.ref("password"), null], "Passwords er ikke ens."),
              first_name: Yup.string().required("Krævet"),
              last_name: Yup.string().required("Krævet"),
              region: Yup.string().required("Krævet"),
              birthday: Yup.string()
                .required("Din fødselsdag mangler.")
                .matches(
                  /[0-9][0-9]-[0-9][0-9]-[0-9][0-9][0-9][0-9]/,
                  "Skriv din fødselsdag med dd-mm-åååå fx. 24-12-1985 "
                ),
            })}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
            }) => (
              <>
                <FormControl isRequired isInvalid={"first_name" in errors}>
                  <FormControl.Label>Fornavn</FormControl.Label>
                  <Input
                    onBlur={handleBlur("first_name")}
                    placeholder="Fornavn"
                    onChangeText={handleChange("first_name")}
                    value={values.first_name}
                  />
                  <FormControl.ErrorMessage>
                    {errors.first_name}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"last_name" in errors}>
                  <FormControl.Label>Efternavn</FormControl.Label>
                  <Input
                    onBlur={handleBlur("last_name")}
                    placeholder="Efternavn"
                    onChangeText={handleChange("last_name")}
                    value={values.last_name}
                  />
                  <FormControl.ErrorMessage>
                    {errors.last_name}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl>
                  <FormControl.Label>Dit kørekort</FormControl.Label>
                  <Checkbox.Group
                    defaultValue={licenseCategories}
                    onChange={(values) => {
                      setLicenseCategories(values || []);
                    }}
                    accessibilityLabel="vælg dine kørekortkategorier"
                  >
                    <Checkbox value="A">A: Kørekort til motorcykel.</Checkbox>
                    <Checkbox value="B">
                      B: Person- og varebil på indtil 3.500 kg og med plads til
                      højst ni personer
                    </Checkbox>
                    <Checkbox value="C">
                      C: Stor lastbil på over 3.500 kg
                    </Checkbox>
                    <Checkbox value="D">
                      D: Bus med plads til flere end ni personer eller med en
                      tilladt totalvægt på over 3.500 kg.
                    </Checkbox>
                    <Checkbox value="E">
                      E: Stort påhængskøretøj på over 750 kg i forbindelse med
                      B, C eller D.
                    </Checkbox>
                  </Checkbox.Group>
                </FormControl>

                <FormControl isRequired isInvalid={"region" in errors}>
                  <FormControl.Label>Din region</FormControl.Label>
                  <Select
                    defaultValue={values.region}
                    accessibilityLabel="Vælg din region"
                    placeholder="Vælg din region"
                    mt={1}
                    value={values.region}
                    onValueChange={handleChange("region")}
                    onBlur={handleBlur("region")}
                    style={{ display: "block" }}
                  >
                    <Select.Item label="Nordjylland" value="nord" />
                    <Select.Item label="Midtjylland" value="midt" />
                    <Select.Item label="Syddanmark" value="syd" />
                    <Select.Item label="Hovedstaden" value="hovedstaden" />
                    <Select.Item label="Sjælland" value="sjælland" />
                  </Select>
                </FormControl>

                <FormControl isRequired isInvalid={"birthday" in errors}>
                  <FormControl.Label>Din fødsesdag</FormControl.Label>
                  <Input
                    onBlur={handleBlur("birthday")}
                    placeholder="24-12-1985"
                    onChangeText={handleChange("birthday")}
                    value={values.birthday}
                  />
                  <FormControl.ErrorMessage>
                    {errors.birthday}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl>
                  <FormControl.Label>Din email</FormControl.Label>
                  <Text>{invite?.email}</Text>
                </FormControl>

                <FormControl isRequired isInvalid={"password" in errors}>
                  <FormControl.Label>Password</FormControl.Label>
                  <Input
                    type={showPass ? "text" : "password"}
                    InputRightElement={
                      <Button
                        size="xs"
                        rounded="none"
                        w="1/6"
                        h="full"
                        onPress={toggleShowPass}
                        bgColor="primary.500"
                      >
                        {showPass ? "Gem" : "Vis"}
                      </Button>
                    }
                    onBlur={handleBlur("password")}
                    placeholder="<hemmelig kode>"
                    onChangeText={handleChange("password")}
                    value={values.password}
                  />
                  <FormControl.ErrorMessage>
                    {errors.password}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"passwordVerify" in errors}>
                  <FormControl.Label>Gentag Password</FormControl.Label>
                  <Input
                    type={showPass2 ? "text" : "password"}
                    InputRightElement={
                      <Button
                        size="xs"
                        rounded="none"
                        w="1/6"
                        h="full"
                        onPress={toggleShowPass2}
                        bgColor="primary.500"
                      >
                        {showPass2 ? "Gem" : "Vis"}
                      </Button>
                    }
                    onBlur={handleBlur("passwordVerify")}
                    placeholder="<samme hemmelige kode>"
                    onChangeText={handleChange("passwordVerify")}
                    value={values.passwordVerify}
                  />
                  <FormControl.ErrorMessage>
                    {errors.passwordVerify}
                  </FormControl.ErrorMessage>
                </FormControl>

                <Button
                  isDisabled={!isValid}
                  onPress={handleSubmit}
                  bgColor="primary.500"
                >
                  <Text color="white">Opret</Text>
                </Button>
              </>
            )}
          </Formik>
        </Column>
      )}
      <Footer />
    </Center>
  );
}

import React from "react";
import { Badge } from "native-base";

export default function CourseType(props) {
  // console.log("CourseType", props?.type);
  let typeText = "";
  switch (props?.type) {
    case "green":
      typeText = "Grønt kørekursus";
      break;
    case "first_aid":
      typeText = "Førstehjælpskursus";
      break;
    case "ice_driving":
      typeText = "Glatførekursus";
      break;
    case "driving_technique":
      typeText = "Køreteknisk kursus";
      break;
    case "drivers_license":
      typeText = "Kørekort undervisning";
      break;
    default:
      typeText = "ukendt";
      break;
  }

  return <Badge>{typeText}</Badge>;
}

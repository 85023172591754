import React, { useState, useContext, useCallback, useEffect } from "react";

import Editor from "@react-page/editor";
import { cellPlugins } from "../plugins/cellPlugins";
import {
  Heading,
  Column,
  Button,
  HStack,
  FormControl,
  Input,
  Text,
  Badge,
} from "native-base";
import { Link } from "@react-navigation/native";
import { FirebaseContext } from "../context/FirebaseContext";
import {
  doc,
  collection,
  setDoc,
  addDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import Footer from "../components/Footer";
import { debounce } from "../utils/tools";

export default function EditorScreen({ navigation, route }) {
  const firebaseContext = useContext(FirebaseContext);
  const { db } = firebaseContext;
  const [page, setPage] = useState(null);
  const [pageContent, setPageContent] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [pageId, setPageId] = useState(route?.params?.pageId);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  const getPage = useCallback(async (pId) => {
    const _pageIdToLoad = pId || route?.params?.pageId;
    // console.log("loading page...", _pageIdToLoad);
    let pSnap;
    try {
      pSnap = await getDoc(doc(db, "pages", _pageIdToLoad));
    } catch (error) {
      alert(error);
    }
    if (pSnap.exists()) {
      let page = pSnap.data();
      setPageId(pSnap.id);
      // console.log("page", page);
      setPageTitle(page?.title);
      setPageContent(page?.content);
      setPage(page);
    }
  }, []);

  useEffect(() => {
    if (route?.params?.pageId) getPage(route?.params?.pageId);
  }, [route?.params?.pageId]);

  const savePage = async (asDraft) => {
    // console.log("savePage... asDraft", asDraft);
    const dataObj = {
      title: pageTitle,
      created: serverTimestamp(),
      content: pageContent,
      published: !asDraft,
    };
    // console.log("submiting ", dataObj);
    let _pId;
    if (pageId) {
      delete dataObj.created; //do not overwrite created
      dataObj.updated = serverTimestamp();
      await setDoc(doc(db, "pages", pageId), dataObj, { merge: true });
      _pId = pageId;
    } else {
      const docRef = await addDoc(collection(db, "pages"), dataObj);
      setPageId(docRef.id);
      _pId = docRef.id;
    }
    await getPage(_pId);
  };
  const duplicatePage = async () => {
    // console.log("dupePage...");
    const dataObj = {
      title: `Kopi af ${pageTitle}`,
      created: serverTimestamp(),
      content: pageContent,
      published: false,
    };
    const docRef = await addDoc(collection(db, "pages"), dataObj);
    const newPageId = docRef.id;
    navigation.navigate("Editor", {
      pageId: newPageId,
    });
  };
  return (
    <Column alignItems="center" mx={dimensions.width < 1025 ? 0 : 40}>
      <Heading size="lg">Rediger: {pageTitle || "ny side"}</Heading>
      <Badge>{page?.published ? "Udgivet" : "Kladde"}</Badge>
      {pageId && (
        <Link to={`/view?pageId=${pageId}`}>
          <Text>id:{pageId}</Text>
        </Link>
      )}
      <HStack w="50%" justifyContent="space-evenly">
        {/* <Link to={{ screen: "Pages" }}>
          <Button bg="danger.500">Fortryd rettelser</Button>
        </Link> */}
        <Button
          isDisabled={pageTitle?.length === 0}
          variant="outline"
          onPress={async () => await savePage(true)}
        >
          <Text>Gem siden som kladde</Text>
        </Button>
        <Button
          isDisabled={pageTitle?.length === 0}
          variant="outline"
          onPress={async () => await duplicatePage()}
        >
          <Text>Duplikér siden</Text>
        </Button>

        <Button
          isDisabled={pageTitle?.length === 0}
          bg="primary.500"
          onPress={() => savePage(false)}
        >
          Gem og udgiv siden
        </Button>
      </HStack>
      <FormControl isRequired w="50%">
        <FormControl.Label>Side titel</FormControl.Label>
        <Input
          // onBlur={handleBlur("name")}
          placeholder="Sidens navn"
          onChangeText={setPageTitle}
          value={pageTitle}
        />
      </FormControl>
      <Editor
        cellPlugins={cellPlugins}
        value={pageContent}
        onChange={setPageContent}
      />
      <Footer />
    </Column>
  );
}

import { createElement } from "react-native-web";

export default function DateTimePicker({ value, style, onChange }) {
  return createElement("input", {
    type: "datetime-local",
    value: value,
    onInput: onChange,
    className: style,
  });
}

import React, { useState, useEffect, useContext } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  FormControl,
  Input,
  Text,
  Checkbox,
  Select,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext, readUserData } from "../context/AuthContext";
import { sendPasswordResetEmail, signOut } from "firebase/auth";

import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import Footer from "../components/Footer";

export default function ProfileScreen({ navigation }) {
  const [userDbData, setUserDbData] = useState();
  const [isReady, setIsReady] = useState(false);
  const [licenseCategories, setLicenseCategories] = useState(["B"]);
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const { user, userData } = authContext;
  const { db, auth } = firebaseContext;

  const onLoad = async () => {
    try {
      const iSnap = await getDoc(doc(db, "users", user.uid));
      if (iSnap.exists()) {
        let _userData = iSnap.data();
        setUserDbData(_userData);
        console.log("found userData", _userData);
        setLicenseCategories(_userData?.license_categories);
        console.log("found license cats", _userData?.license_categories);
        setIsReady(true);
      } else {
        console.log("user not found!!!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onSubmit = async (data) => {
    console.log("submiting with ", data, licenseCategories);

    try {
      const { auth, db } = firebaseContext;
      // console.log("onSubmit invite", invite);
      const dataObj = {
        first_name: data.first_name,
        last_name: data.last_name,
        license_categories: licenseCategories,
        region: data.region,
        updated: serverTimestamp(),
      };
      await setDoc(doc(db, "users", user.uid), dataObj, { merge: true });
      alert("Din profil er gemt");
    } catch (error) {
      alert("Edit Profile Failed", error);
    }
  };

  const onResetPassword = async (data) => {
    const { auth } = firebaseContext;
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        alert(
          "Du har nu fået tilsendt en email med mulighed for at nulstille din adgangskode"
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Password reset failed", errorCode, errorMessage);
      });
  };

  const onLogout = async (data) => {
    console.log("logging out");
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful.");
      })
      .catch((error) => {
        console.log("error", error);
        // An error happened.
      });
  };

  return (
    <Center>
      <Column>
        <Heading size="lg">Min Profil</Heading>
        {isReady && (
          <Formik
            initialValues={{ ...user, ...userDbData }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required("Krævet"),
              last_name: Yup.string().required("Krævet"),
              region: Yup.string().required("Krævet"),
            })}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              // setFieldValue,
              values,
              errors,
              isValid,
            }) => {
              // useEffect(() => {
              //   const fields = ["first_name", "last_name", "region"];
              //   fields.forEach((field) => {
              //     setFieldValue(field, userDbData[field], false);
              //   });
              //   // setLicenseCategories(userDbData.license_categories);
              // }, []);
              return (
                <>
                  <FormControl isRequired isInvalid={"first_name" in errors}>
                    <FormControl.Label>Fornavn</FormControl.Label>
                    <Input
                      onBlur={handleBlur("first_name")}
                      placeholder="Fornavn"
                      onChangeText={handleChange("first_name")}
                      value={values.first_name}
                    />
                    <FormControl.ErrorMessage>
                      {errors.first_name}
                    </FormControl.ErrorMessage>
                  </FormControl>

                  <FormControl isRequired isInvalid={"last_name" in errors}>
                    <FormControl.Label>Efternavn</FormControl.Label>
                    <Input
                      onBlur={handleBlur("last_name")}
                      placeholder="Efternavn"
                      onChangeText={handleChange("last_name")}
                      value={values.last_name}
                    />
                    <FormControl.ErrorMessage>
                      {errors.last_name}
                    </FormControl.ErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormControl.Label>Dit kørekort</FormControl.Label>
                    <Checkbox.Group
                      defaultValue={licenseCategories}
                      onChange={(values) => {
                        setLicenseCategories(values || []);
                      }}
                      accessibilityLabel="vælg dine kørekortkategorier"
                    >
                      <Checkbox value="A">A: Kørekort til motorcykel.</Checkbox>
                      <Checkbox value="B">
                        B: Person- og varebil på indtil 3.500 kg og med plads
                        til højst ni personer
                      </Checkbox>
                      <Checkbox value="C">
                        C: Stor lastbil på over 3.500 kg
                      </Checkbox>
                      <Checkbox value="D">
                        D: Bus med plads til flere end ni personer eller med en
                        tilladt totalvægt på over 3.500 kg.
                      </Checkbox>
                      <Checkbox value="E">
                        E: Stort påhængskøretøj på over 750 kg i forbindelse med
                        B, C eller D.
                      </Checkbox>
                    </Checkbox.Group>
                  </FormControl>

                  <FormControl isRequired isInvalid={"region" in errors}>
                    <FormControl.Label>Din region</FormControl.Label>
                    <Select
                      defaultValue={values.region}
                      accessibilityLabel="Vælg din region"
                      placeholder="Vælg din region"
                      mt={1}
                      value={values.region}
                      onValueChange={handleChange("region")}
                      onBlur={handleBlur("region")}
                      style={{ display: "block" }}
                    >
                      <Select.Item label="Nordjylland" value="nord" />
                      <Select.Item label="Midtjylland" value="midt" />
                      <Select.Item label="Syddanmark" value="syd" />
                      <Select.Item label="Hovedstaden" value="hovedstaden" />
                      <Select.Item label="Sjælland" value="sjælland" />
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormControl.Label>Din email</FormControl.Label>
                    <Text>{user.email}</Text>
                  </FormControl>

                  <Button
                    onPress={() => onResetPassword(values)}
                    variant="outline"
                  >
                    <Text>Nulstil password</Text>
                  </Button>

                  <Button
                    isDisabled={!isValid || licenseCategories?.length == 0}
                    onPress={handleSubmit}
                    bgColor="primary.500"
                  >
                    <Text color="white">Gem</Text>
                  </Button>
                  <Button
                    onPress={() => navigation.navigate("Member")}
                    variant="outline"
                  >
                    <Text>Afbryd</Text>
                  </Button>
                </>
              );
            }}
          </Formik>
        )}
        <Button variant="subtle" onPress={onLogout}>
          <Text>Logout</Text>
        </Button>
      </Column>
      <Footer />
    </Center>
  );
}

import type { CellPlugin } from "@react-page/editor";
import React from "react";
import { connectField } from "uniforms";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

const ImageUploadField = connectField(({ value, onChange }) => {
  return (
    <div>
      <p>Artikel billede: </p>
      {value ? <img style={{ width: 150 }} src={value} /> : null}

      <input
        type="file"
        onChange={async (e) => {
          if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            // console.log('file',file);
            const fileRef = ref(getStorage(), v4());
            const result = await uploadBytes(fileRef, file);
            const finalUrl = await getDownloadURL(fileRef);
            // console.log('finalUrl',finalUrl);
            onChange(finalUrl);
          }
        }}
      />
    </div>
  );
});
const ArticleHeadPlugin: CellPlugin<{
  title: string;
  published: string;
  teaser: string;
  imageUrl: string;
  imageSubtitle: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <h1 style={{ fontFamily: "Roboto", fontWeight: 800 }}>{data.title}</h1>
      <p
        style={{
          color: "gray",
          fontFamily: "Roboto",
          fontWeight: 500,
          fontSize: 12,
        }}
      >
        {data.published}
      </p>
      <div
        style={{
          fontSize: 14,
          fontWeight: 400,
          color: "gray",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {data?.teaser}
      </div>
      <img style={{ width: "100%" }} src={data.imageUrl} />
      <div
        style={{
          fontSize: 14,
          fontWeight: 400,
          color: "gray",
          paddingTop: 5,
          paddingBottom: 20,
        }}
      >
        {data?.imageSubtitle}
      </div>
    </div>
  ),
  id: "article-head-plugin",
  title: "Article Head plugin",
  description: "Artikel hoved plugin",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        title: {
          type: "string",
          default: "Lækker overskrift",
        },
        teaser: {
          type: "string",
          default: "Spændende teaser",
        },
        imageSubtitle: {
          type: "string",
          default: "Lidt om billedet",
        },
        published: { type: "string" },
        imageUrl: {
          type: "string",
          uniforms: {
            component: ImageUploadField,
          },
        },
      },
      required: ["title", "published", "imageUrl"],
    },
  },
};
export default ArticleHeadPlugin;

import React, { useContext, useEffect, useCallback, useState } from "react";
import { FlatList, Box, Button, Spacer, Text, Icon, HStack } from "native-base";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import CourseType from "./CourseType";
import Region from "./Region";
import { Entypo } from "@expo/vector-icons";

export default function TeachersList(props) {
  const authContext = useContext(AuthContext);
  const firebaseContext = useContext(FirebaseContext);
  const [teachers, setTeachers] = useState();
  const { db } = firebaseContext;
  const { userData } = authContext;

  const getTeachers = async () => {
    try {
      const queryConstraints = [];
      if (props?.type) {
        queryConstraints.push(where("type", "array-contains", props.type));
      }
      if (props?.region) {
        queryConstraints.push(where("region", "==", props.region));
      }
      // console.log("getTeachers querysConstrains", queryConstraints);
      const q = query(collection(db, "teachers"), ...queryConstraints);
      const unsub = onSnapshot(q, (qSnap) => {
        const _teachers = [];
        qSnap.forEach((doc) => {
          let t = doc.data();
          t.tid = doc.id;
          _teachers.push(t);
        });
        setTeachers(_teachers);
        // console.log("Teachers: ", _teachers);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    // console.log("teacherlist props change", props);
    getTeachers();
  }, [props]);

  return (
    <FlatList
      data={teachers}
      renderItem={({ item }) => (
        <Box direction="row" bg="gray.200" p="2" m="1" rounded="lg">
          <Text bold>{item.name}</Text>
          {!props?.type &&
            item.type.map((t) => <CourseType key={t} type={t} />)}
          {item.address}
          <Text>
            {item.zip} {item.city}
          </Text>
          {props.showRegion && <Region region={item.region} />}
          <Spacer />
          {userData?.super_admin && !props.onPick && (
            <Button onPress={() => props?.onEdit(item)} bgColor="primary.500">
              <Text color="white">Rediger</Text>
            </Button>
          )}
          {props.onPick && (
            <Button bgColor="primary.500" onPress={() => props?.onPick(item)}>
              <Text color="white">Vælg</Text>
            </Button>
          )}
        </Box>
      )}
      keyExtractor={(item) => item.tid}
      ListEmptyComponent={
        <HStack space={2}>
          <Icon
            as={Entypo}
            name="warning"
            size="5"
            mt="0.5"
            color="danger.500"
          />
          <Text bold>Ingen relevante undervisere</Text>
        </HStack>
      }
    />
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  Checkbox,
  Box,
  FormControl,
  Input,
  Select,
  Text,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext } from "../context/AuthContext";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import TeachersList from "../components/TeachersList";
import Footer from "../components/Footer";

export default function TeachersScreen({ navigation }) {
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const { userData } = authContext;
  const [showEditor, setShowEditor] = useState(false);
  const toggleShowEditor = () => setShowEditor(!showEditor);
  const [editTeacher, setEditTeacher] = useState(null);
  const [editTeacherTypes, setEditTeacherTypes] = useState(["drivers_license"]);
  const { db } = firebaseContext;

  const storeTeacher = async (data) => {
    console.log("submiting with ", data, editTeacherTypes);
    if (data.tid) {
      await setDoc(
        doc(db, "teachers", data.tid),
        {
          name: data.name,
          cvr: data.cvr,
          address: data.address,
          zip: data.zip,
          city: data.city,
          region: data.region,
          phone: data.phone,
          email: data.email,
          type: editTeacherTypes,
          updated: serverTimestamp(),
        },
        { merge: true }
      );
      setEditTeacher(null);
    } else {
      await addDoc(collection(db, "teachers"), {
        name: data.name,
        cvr: data.cvr,
        address: data.address,
        zip: data.zip,
        city: data.city,
        region: data.region,
        phone: data.phone,
        email: data.email,
        type: editTeacherTypes,
        created: serverTimestamp(),
      });
    }
    toggleShowEditor();
  };
  const doEditTeacher = (item) => {
    setEditTeacher(item);
    // console.log("edit", item);
    toggleShowEditor();
  };

  const initialValues = __DEV__
    ? {
        name: "Ole Underarm",
        cvr: "43285718",
        address: "Hørvej 1",
        zip: "9999",
        city: "Køreby",
        region: "nord",
        phone: "+4580808080",
        email: "teacher@koreskole.dk",
        type: [],
      }
    : {
        name: "",
        cvr: "",
        address: "",
        zip: "",
        city: "",
        region: "nord",
        phone: "",
        email: "",
        type: [],
      };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Krævet"),
    cvr: Yup.string()
      .min(8, "CVR er for kort")
      .max(8, "CVR er for langt")
      .required("Krævet"),
    phone: Yup.string().required("Krævet"),
    address: Yup.string().required("Krævet"),
    zip: Yup.string()
      .min(4, "postnummer er for kort - skal være 4 cifre")
      .max(4, "postnummer er for langt - skal være 4 cifre")
      .matches(/[0-9]/, "Postnummer skal være 4 talcifre.")
      .required("Postnummer mangler."),
    city: Yup.string().required("Krævet"),
    region: Yup.string().required("Krævet"),
    phone: Yup.string().required("Krævet"),
    email: Yup.string().email("Ikke en email adresse").required("Krævet"),
  });

  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Undervisere</Heading>
      {userData?.super_admin && (
        <Center>
          {showEditor ? (
            <Formik
              initialValues={initialValues}
              onSubmit={storeTeacher}
              validationSchema={validationSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isValid,
              }) => {
                useEffect(() => {
                  // console.log("editTeacher", editTeacher);
                  const fields = [
                    "name",
                    "cvr",
                    "address",
                    "zip",
                    "city",
                    "region",
                    "phone",
                    "email",
                    "tid",
                  ];
                  if (editTeacher) {
                    fields.forEach((field) => {
                      setFieldValue(field, editTeacher[field], false);
                      // console.log("setFieldValue", field, editTeacher[field]);
                    });
                    setEditTeacherTypes(editTeacher.type);
                    // console.log("fields", fields);
                    // console.log("values", values);
                  } else {
                    // fields.forEach((field) => setFieldValue(field, "", false));
                    setEditTeacherTypes(["drivers_license"]);
                  }
                }, []);
                return (
                  <Box shadow={1} p={4}>
                    <Heading size="sm">
                      {editTeacher ? (
                        <>Rediger underviser</>
                      ) : (
                        <>Opret ny underviser</>
                      )}
                    </Heading>
                    <FormControl isRequired isInvalid={"name" in errors}>
                      <FormControl.Label>Navn</FormControl.Label>
                      <Input
                        onBlur={handleBlur("name")}
                        placeholder="Undervisers navn"
                        onChangeText={handleChange("name")}
                        value={values.name}
                      />
                      <FormControl.ErrorMessage>
                        {errors.name}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"cvr" in errors}>
                      <FormControl.Label>CVR</FormControl.Label>
                      <Input
                        onBlur={handleBlur("cvr")}
                        placeholder="43285718"
                        onChangeText={handleChange("cvr")}
                        value={values.cvr}
                      />
                      <FormControl.ErrorMessage>
                        {errors.cvr}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"address" in errors}>
                      <FormControl.Label>Adresse</FormControl.Label>
                      <Input
                        onBlur={handleBlur("address")}
                        placeholder="Nygade 12"
                        onChangeText={handleChange("address")}
                        value={values.address}
                      />
                      <FormControl.ErrorMessage>
                        {errors.address}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"zip" in errors}>
                      <FormControl.Label>Postnummer</FormControl.Label>
                      <Input
                        onBlur={handleBlur("zip")}
                        placeholder="9000"
                        onChangeText={handleChange("zip")}
                        value={values.zip}
                      />
                      <FormControl.ErrorMessage>
                        {errors.zip}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"city" in errors}>
                      <FormControl.Label>By</FormControl.Label>
                      <Input
                        onBlur={handleBlur("city")}
                        placeholder="Gammelby"
                        onChangeText={handleChange("city")}
                        value={values.city}
                      />
                      <FormControl.ErrorMessage>
                        {errors.city}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"region" in errors}>
                      <FormControl.Label>Region</FormControl.Label>
                      <Select
                        defaultValue={values.region}
                        accessibilityLabel="Vælg region"
                        placeholder="Vælg region"
                        mt={1}
                        selectedValue={values.region}
                        onValueChange={handleChange("region")}
                        onBlur={handleBlur("region")}
                        style={{ display: "block" }}
                      >
                        <Select.Item label="Nordjylland" value="nord" />
                        <Select.Item label="Midtjylland" value="midt" />
                        <Select.Item label="Syddanmark" value="syd" />
                        <Select.Item label="Hovedstaden" value="hovedstaden" />
                        <Select.Item label="Sjælland" value="sjælland" />
                      </Select>
                    </FormControl>

                    <FormControl isRequired isInvalid={"phone" in errors}>
                      <FormControl.Label>Telefonnummer</FormControl.Label>
                      <Input
                        onBlur={handleBlur("phone")}
                        placeholder="80123456"
                        onChangeText={handleChange("phone")}
                        value={values.phone}
                      />
                      <FormControl.ErrorMessage>
                        {errors.phone}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"email" in errors}>
                      <FormControl.Label>Din email</FormControl.Label>
                      <Input
                        onBlur={handleBlur("email")}
                        placeholder="navn@firma.dk"
                        onChangeText={handleChange("email")}
                        value={values.email}
                      />
                      <FormControl.ErrorMessage>
                        {errors.email}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"type" in errors}>
                      <FormControl.Label>Type</FormControl.Label>
                      <Checkbox.Group
                        // defaultValue={types}
                        onChange={(values) => {
                          setEditTeacherTypes(values || []);
                        }}
                        accessibilityLabel="vælg fag"
                        value={editTeacherTypes}
                      >
                        <Checkbox
                          value="drivers_license"
                          accessibilityLabel="Kørekort undervisning"
                        >
                          Kørekort undervisning
                        </Checkbox>
                        <Checkbox
                          value="first_aid"
                          accessibilityLabel="Førstehjælp"
                        >
                          Førstehjælp
                        </Checkbox>
                        <Checkbox
                          value="green"
                          accessibilityLabel="Grønt kørekursus"
                        >
                          Grønt kørekursus
                        </Checkbox>
                        <Checkbox
                          value="ice_driving"
                          accessibilityLabel="Glatføre"
                        >
                          Glatføre
                        </Checkbox>
                        <Checkbox
                          value="driving_technique"
                          accessibilityLabel="Køreteknisk kursus"
                        >
                          Køreteknisk kursus
                        </Checkbox>
                      </Checkbox.Group>
                    </FormControl>

                    <Button
                      isDisabled={!isValid || editTeacherTypes.length == 0}
                      onPress={handleSubmit}
                      bgColor="primary.500"
                    >
                      {editTeacher ? (
                        <Text color="white">Gem underviser</Text>
                      ) : (
                        <Text color="white">Opret underviser</Text>
                      )}
                    </Button>
                    <Button variant="outline" onPress={toggleShowEditor}>
                      <Text>Afbryd</Text>
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          ) : (
            <Button
              bg="primary.500"
              key="createBtn"
              onPress={() => {
                setEditTeacher(null);
                toggleShowEditor();
              }}
            >
              Opret ny underviser
            </Button>
          )}
        </Center>
      )}
      <TeachersList onEdit={doEditTeacher} showRegion={true} />
      <Footer />
    </Column>
  );
}

import React, { useContext, useState, useEffect } from "react";
import {
  Text,
  Image,
  HStack,
  Link,
  Spacer,
  Menu,
  Pressable,
  Icon,
  Divider,
  Center,
} from "native-base";
import {
  DefaultTheme,
  NavigationContainer,
  Link as NavLink,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useAuth, authReady, AuthContext } from "./context/AuthContext";
import FrontScreen from "./screens/FrontScreen";
import SignUpScreen from "./screens/SignUpScreen";
import LoginScreen from "./screens/LoginScreen";
import MemberScreen from "./screens/MemberScreen";
import CompanyScreen from "./screens/CompanyScreen";
import EnrolmentScreen from "./screens/EnrolmentScreen";
import EmployeesScreen from "./screens/EmployeesScreen";
import CoursesScreen from "./screens/CoursesScreen";
import DisqualificationScreen from "./screens/DisqualificationScreen";
import AcceptInviteScreen from "./screens/AcceptInviteScreen";
import TeachersScreen from "./screens/TeachersScreen";
import CompaniesScreen from "./screens/CompaniesScreen";
import AdminScreen from "./screens/AdminScreen";
import ProfileScreen from "./screens/ProfileScreen";
import LocationsScreen from "./screens/LocationsScreen";
import EditorScreen from "./screens/EditorScreen";
import ViewPageScreen from "./screens/ViewPageScreen";
import PagesScreen from "./screens/PagesScreen";
import Spinner from "./components/Spinner";
import { Entypo } from "@expo/vector-icons";
import { debounce } from "./utils/tools";

const Stack = createNativeStackNavigator();
const linking = {
  // prefixes: ["https://app.licensecare.dk", "localhost"],
  config: {
    screens: {
      Home: "",
      SignUp: "signup",
      Login: "login",
      AcceptInvite: "acceptInvite/:companyId/:inviteId",
      Member: "member",
      Company: "company",
      Enrolment: "enrolment",
      Employees: "employees",
      Courses: "courses",
      Teachers: "teachers",
      Locations: "locations",
      Editor: "editor",
      ViewPage: "view",
      Pages: "pages",
      Companies: "companies",
      Admin: "admin",
      Profile: "profile",
      Renewal: "renewal",
      Disqualification: "disqualification",
      // TODO: NotFound: "404",
    },
  },
};

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "transparent",
  },
};
function LogoTitle() {
  return (
    <NavLink to="/member">
      <Image
        style={{ width: 144, height: 65 }}
        source={require("./assets/logo.png")}
        alt="LicenseCare Logo"
      />
    </NavLink>
  );
}

export default function AppNavigation() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const authContext = useContext(AuthContext);
  const { userData } = authContext;
  const user = useAuth();
  const ready = authReady();

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  if (!ready) return <Spinner />;
  return (
    <NavigationContainer
      linking={linking}
      fallback={<Text>Loading...</Text>}
      theme={navTheme}
    >
      <Stack.Navigator
        screenOptions={{
          header: (props) => (
            <HStack
              style={{
                height: 100,
                backgroundColor: "white",
                padding: 10,
              }}
              mx={dimensions.width < 1025 ? 0 : 20}
            >
              <LogoTitle {...props} />
              <Spacer />
              {dimensions.width < 1025 ? (
                <Menu
                  w="190"
                  placement="right"
                  trigger={(triggerProps) => {
                    return (
                      <Pressable accessibilityLabel="Menu" {...triggerProps}>
                        <Icon
                          as={Entypo}
                          name="menu"
                          size="8"
                          mt="0.5"
                          p="2"
                          color="black"
                        />
                      </Pressable>
                    );
                  }}
                >
                  {!user && (
                    <NavLink to="/signup">
                      <Menu.Item>Opret firmaaftale</Menu.Item>
                    </NavLink>
                  )}
                  {!user && (
                    <NavLink to="/login">
                      <Menu.Item>Login</Menu.Item>
                    </NavLink>
                  )}

                  {user && userData?.company_admin && (
                    <>
                      {/* <Divider mt="3" w="100%" /> */}
                      <Menu.Group color="danger.500" title="Firma Admin">
                        <NavLink to="/employees">
                          <Menu.Item>Administrer medarbejdere</Menu.Item>
                        </NavLink>
                        <NavLink to="/enrolment">
                          <Menu.Item>Administrer kursustilmeldinger</Menu.Item>
                        </NavLink>
                        <NavLink to="/company">
                          <Menu.Item>Firma stamdata</Menu.Item>
                        </NavLink>
                      </Menu.Group>
                      <Divider mt="3" w="100%" />
                    </>
                  )}
                  {user && userData?.super_admin && (
                    <>
                      {/* <Divider mt="3" w="100%" /> */}
                      <Menu.Group title="SUPER ADMIN">
                        <NavLink to="/teachers">
                          <Menu.Item>Undervisere</Menu.Item>
                        </NavLink>
                        <NavLink to="/locations">
                          <Menu.Item>Undervisningssteder</Menu.Item>
                        </NavLink>
                        <NavLink to="/courses">
                          <Menu.Item>Kurser</Menu.Item>
                        </NavLink>
                        <NavLink to="/companies">
                          <Menu.Item>Virksomheder</Menu.Item>
                        </NavLink>
                        <NavLink to="/admin">
                          <Menu.Item>Fakturering mv.</Menu.Item>
                        </NavLink>
                        <NavLink to="/pages">
                          <Menu.Item>Rediger sider</Menu.Item>
                        </NavLink>
                      </Menu.Group>
                      <Divider mt="3" w="100%" />
                    </>
                  )}
                  {user && (
                    <NavLink to="/courses">
                      {userData?.super_admin ? (
                        <Menu.Item>Kurser</Menu.Item>
                      ) : (
                        <Menu.Item>Mine Kurser</Menu.Item>
                      )}
                    </NavLink>
                  )}
                  {user && (
                    <NavLink to="/disqualification">
                      <Menu.Item>Opret frakendelse</Menu.Item>
                    </NavLink>
                  )}
                  {user && (
                    <Link href="/view?pageId=VTLokhha11FDssHUPvdM">
                      <Menu.Item>FAQ</Menu.Item>
                    </Link>
                  )}
                  {user && (
                    <NavLink to="/profile">
                      <Menu.Item>Min Profil</Menu.Item>
                    </NavLink>
                  )}
                </Menu>
              ) : (
                //wide menu
                <>
                  {!user && (
                    <>
                      <Center key="opret">
                        <NavLink to="/signup">
                          <Text bold>Opret firmaaftale</Text>
                        </NavLink>
                      </Center>
                      <Spacer key="s1" />
                      <Center key="login">
                        <NavLink to="/login">
                          <Text bold>Login</Text>
                        </NavLink>
                      </Center>
                      <Spacer key="s2" />
                    </>
                  )}

                  {user && (
                    <>
                      <Center key="c2">
                        <NavLink to="/courses">
                          {userData?.super_admin ? (
                            <Text bold>Kurser</Text>
                          ) : (
                            <Text bold>Mine Kurser</Text>
                          )}
                        </NavLink>
                      </Center>
                      <Spacer key="s3b" />
                      <Center key="c3">
                        <NavLink to="/disqualification">
                          <Text bold>Opret Frakendelse</Text>
                        </NavLink>
                      </Center>
                      <Spacer key="s4" />
                      {userData?.company_admin && (
                        <>
                          <Center key="compadmin">
                            <Menu
                              key="compadminmenu"
                              trigger={(triggerProps) => {
                                return (
                                  <Center>
                                    <Pressable
                                      accessibilityLabel="Firma ADMIN"
                                      {...triggerProps}
                                    >
                                      <Text color="danger.500" bold>
                                        Firma Admin
                                      </Text>
                                    </Pressable>
                                  </Center>
                                );
                              }}
                            >
                              <NavLink to="/employees">
                                <Menu.Item>Administrer medarbejdere</Menu.Item>
                              </NavLink>
                              <NavLink to="/enrolment">
                                <Menu.Item>
                                  Administrer kursustilmeldinger
                                </Menu.Item>
                              </NavLink>
                              <NavLink to="/company">
                                <Menu.Item>Firma stamdata</Menu.Item>
                              </NavLink>
                            </Menu>
                          </Center>
                          <Spacer key="s5" />
                        </>
                      )}
                      {userData?.super_admin && (
                        <>
                          <Menu
                            key="superadminmenu"
                            trigger={(triggerProps) => {
                              return (
                                <Center>
                                  <Pressable
                                    accessibilityLabel="SUPER ADMIN"
                                    {...triggerProps}
                                  >
                                    <Text bold color="danger.500">
                                      SUPER ADMIN
                                    </Text>
                                  </Pressable>
                                </Center>
                              );
                            }}
                          >
                            <NavLink to="/teachers">
                              <Menu.Item>Undervisere</Menu.Item>
                            </NavLink>
                            <NavLink to="/locations">
                              <Menu.Item>Undervisningssteder</Menu.Item>
                            </NavLink>
                            <NavLink to="/courses">
                              <Menu.Item>Kurser</Menu.Item>
                            </NavLink>
                            <NavLink to="/companies">
                              <Menu.Item>Virksomheder</Menu.Item>
                            </NavLink>
                            <NavLink to="/admin">
                              <Menu.Item>Fakturering mv.</Menu.Item>
                            </NavLink>
                            <NavLink to="/pages">
                              <Menu.Item>Rediger sider</Menu.Item>
                            </NavLink>
                          </Menu>
                          <Spacer key="s6" />
                        </>
                      )}

                      <Center key="c11">
                        <Link href="/view?pageId=VTLokhha11FDssHUPvdM">
                          <Text bold>FAQ</Text>
                        </Link>
                      </Center>
                      <Spacer key="s7" />
                      <Center key="c12">
                        <NavLink to="/profile">
                          <Text bold>Min Profil</Text>
                        </NavLink>
                      </Center>
                    </>
                  )}
                </>
              )}
            </HStack>
          ),
          headerShown: true,
          headerBackVisible: false,
        }}
      >
        {user ? (
          <>
            <Stack.Screen name="Member" component={MemberScreen} />
            <Stack.Screen name="ViewPage" component={ViewPageScreen} />
            <Stack.Screen name="Courses" component={CoursesScreen} />
            <Stack.Screen name="Profile" component={ProfileScreen} />
            <Stack.Screen
              name="Disqualification"
              component={DisqualificationScreen}
            />
            {userData?.company_admin && (
              <>
                <Stack.Screen name="Company" component={CompanyScreen} />
                <Stack.Screen name="Enrolment" component={EnrolmentScreen} />
                <Stack.Screen name="Employees" component={EmployeesScreen} />
              </>
            )}
            {userData?.super_admin && (
              <>
                <Stack.Screen name="Teachers" component={TeachersScreen} />
                <Stack.Screen name="Locations" component={LocationsScreen} />
                <Stack.Screen name="Editor" component={EditorScreen} />
                <Stack.Screen name="Pages" component={PagesScreen} />
                <Stack.Screen name="Companies" component={CompaniesScreen} />
                <Stack.Screen name="Admin" component={AdminScreen} />
              </>
            )}
          </>
        ) : (
          <>
            <Stack.Screen name="Home" component={FrontScreen} />
            <Stack.Screen name="SignUp" component={SignUpScreen} />
            <Stack.Screen name="AcceptInvite" component={AcceptInviteScreen} />
            <Stack.Screen name="Login" component={LoginScreen} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

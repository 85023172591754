import React, { useContext, useEffect, useCallback, useState } from "react";
import { Flex, Button, Text } from "native-base";
import { FirebaseContext } from "../context/FirebaseContext";
import { getDoc, doc } from "firebase/firestore";
import Region from "./Region";
import LocationType from "./LocationType";

export default function LocationDetails(props) {
  const firebaseContext = useContext(FirebaseContext);
  const [location, setLocation] = useState();
  const [showDetails, setShowDetails] = useState(props?.showDetails);
  const toggleShowDetails = () => setShowDetails(!showDetails);
  const { db } = firebaseContext;

  const getLocation = useCallback(async () => {
    try {
      const lid = props?.locationId;
      // console.log("lid", props);
      const cSnap = await getDoc(doc(db, "locations", lid));
      if (cSnap.exists()) {
        let loc = cSnap.data();
        loc.locationId = lid;
        // console.log("loc", loc);
        setLocation(loc);
      }
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    if (props?.location) {
      setLocation(props.location);
    } else {
      getLocation();
    }
  }, [props.location]);

  return (
    <Flex alignItems="center" direction="row" w="s" p="1">
      <Text>{location?.name}</Text>
      <Button variant="ghost" onPress={toggleShowDetails} p="1">
        {!props?.showDetails && (
          <>{showDetails ? <Text>skjul</Text> : <Text>vis</Text>}</>
        )}
      </Button>
      {/* <Region region={location?.region} /> */}
      {/* {location?.type.map((t) => (
            <LocationType key={t} type={t} />
          ))} */}
      {showDetails && (
        <>
          <Text>
            {location?.address}, {location?.zip} {location?.city}
          </Text>
        </>
      )}
    </Flex>
  );
}

import React, { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../context/FirebaseContext";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

const AuthContext = createContext(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const firebaseContext = useContext(FirebaseContext);
  const { auth, db } = firebaseContext;
  const [authReady, setAuthReady] = useState(false);
  const [user, setUser] = useState(() => {
    const user = auth.currentUser;

    return user;
  });
  const [userData, setUserData] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      setUser(firebaseUser);
      // console.log("onAuthStateChanged user", firebaseUser);
      if (firebaseUser) {
        let userData = await readUserData(db, firebaseUser?.uid);
        if (userData?.companyId) {
          const company = await getDoc(
            doc(db, "companies", userData.companyId)
          );
          const _companyData = company.data();
          userData.companyActive = _companyData.active;
          if (_companyData.deleted) {
            setUser(null);
            setUserData(null);
            alert("Firma er slettet");
          } else {
            setUserData(userData);
          }
        }
      }
      setAuthReady(true);
    });
  }, []);

  return (
    <Provider value={{ user, authReady, userData, setUserData }}>
      {children}
    </Provider>
  );
};

const readUserData = async (db, uid) => {
  const docSnap = await getDoc(doc(db, "users", uid));
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("userData not found");
    return null;
  }
};

const useAuth = () => {
  const { user } = useContext(AuthContext);
  return user;
};

const authReady = () => {
  const { authReady } = useContext(AuthContext);
  return authReady;
};

const signOut = () => {
  const firebaseContext = useContext(FirebaseContext);
  const { auth } = firebaseContext;
  return auth.signOut();
};

export { signOut, useAuth, authReady, readUserData, AuthContext, AuthProvider };

import React, { createContext } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD9xNh6JSogOI4i2ScAdVtbF9akau2j2bs",
  authDomain: "kortklub-f4404.firebaseapp.com",
  projectId: "kortklub-f4404",
  storageBucket: "kortklub-f4404.appspot.com",
  messagingSenderId: "630071937119",
  appId: "1:630071937119:web:76770fd6159b58944473ea",
  measurementId: "G-HV09BEQFYP",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
if (__DEV__) {
  connectFirestoreEmulator(db, "localhost", 8080);
}
const auth = getAuth(app);
if (__DEV__) {
  connectAuthEmulator(auth, "http://localhost:9099");
}
const FirebaseContext = createContext(null);
const { Provider } = FirebaseContext;

const FirebaseProvider = ({ children }) => {
  return (
    <Provider
      value={{
        app,
        db,
        auth,
      }}
    >
      {children}
    </Provider>
  );
};

export { FirebaseContext, FirebaseProvider };

import React, { useState, useContext } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  FormControl,
  Input,
  Text,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import Footer from "../components/Footer";

export default function LoginScreen({ route, navigation }) {
  const [showPass, setShowPass] = useState(false);
  const firebaseContext = useContext(FirebaseContext);

  const toggleShowPass = () => setShowPass(!showPass);

  const onSubmit = async (data) => {
    const { auth } = firebaseContext;
    console.log("submiting with ", data, auth);

    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("User signed in", user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error", errorCode, errorMessage);
        console.log("error", error);
        alert("Login Failed", error.response);
      });
  };
  const onResetPassword = async (data) => {
    const { auth } = firebaseContext;
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        alert(
          "Du har nu fået tilsendt en email med mulighed for at nulstille din adgangskode"
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert("Password reset failed", errorCode, errorMessage);
      });
  };

  return (
    <Column>
      <Formik
        initialValues={{
          email: route?.params?.email || "",
          password: route?.params?.password || "",
        }}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Ikke en email adresse").required("Krævet"),
          password: Yup.string().required("Password mangler."),
        })}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          isValid,
        }) => (
          <Center>
            <Column>
              <Heading size="lg">Medarbejder Login</Heading>
              <FormControl isRequired isInvalid={"email" in errors}>
                <FormControl.Label>Email</FormControl.Label>
                <Input
                  onBlur={handleBlur("email")}
                  placeholder="navn@firma.dk"
                  onChangeText={handleChange("email")}
                  value={values.email}
                />
                <FormControl.ErrorMessage>
                  {errors.email}
                </FormControl.ErrorMessage>
              </FormControl>

              <FormControl isInvalid={"password" in errors}>
                <FormControl.Label>Password</FormControl.Label>
                <Input
                  type={showPass ? "text" : "password"}
                  InputRightElement={
                    <Button
                      size="xs"
                      rounded="none"
                      w="1/6"
                      h="full"
                      onPress={toggleShowPass}
                      bgColor="primary.500"
                    >
                      {showPass ? "Gem" : "Vis"}
                    </Button>
                  }
                  onBlur={handleBlur("password")}
                  placeholder="<hemmelig kode>"
                  onChangeText={handleChange("password")}
                  value={values.password}
                />
                <FormControl.ErrorMessage>
                  {errors.password}
                </FormControl.ErrorMessage>
              </FormControl>

              <Button
                isDisabled={!isValid}
                onPress={handleSubmit}
                bgColor="primary.500"
              >
                <Text color="white">Login</Text>
              </Button>
              <Button onPress={() => onResetPassword(values)} variant="outline">
                <Text>Nulstil password</Text>
              </Button>
            </Column>
          </Center>
        )}
      </Formik>
      <Footer />
    </Column>
  );
}

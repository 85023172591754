import React from "react";
import { Center, Heading, Column, Button, Text } from "native-base";
import { Link } from "@react-navigation/native";
import Footer from "../components/Footer";

export default function FrontScreen({ navigation }) {
  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Velkommen til LicenseCare</Heading>
      <Button bgColor="primary.500">
        <Link to={{ screen: "SignUp" }}>
          <Text color="white">Opret Firmaaftale</Text>
        </Link>
      </Button>
      <Button bgColor="primary.500">
        <Link to={{ screen: "Login" }}>
          <Text color="white">Login</Text>
        </Link>
      </Button>
      <Footer />
    </Column>
  );
}

import React, { useState, useRef } from "react";
import { Center, AlertDialog, Button } from "native-base";

export default function ConfirmEnrolment(props) {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = useRef();

  const onConfirm = () => {
    // console.log("onConfirm");
    onClose();
    props?.onConfirm();
  };

  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.Header fontSize="lg" fontWeight="bold">
            Tilmeld dig til kursus
          </AlertDialog.Header>
          <AlertDialog.Body>
            Er du sikker? Din tilmelding er bindende
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button ref={cancelRef} onPress={onClose}>
              Fortryd
            </Button>
            <Button bgColor="primary.500" onPress={onConfirm} ml={3}>
              <Text color="white">Tilmeld</Text>
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <Button bgColor="primary.500" onPress={() => setIsOpen(!isOpen)}>
        <Text color="white">{props.children}</Text>
      </Button>
    </Center>
  );
}

import React from "react";
import { Box, Text, HStack, VStack, Heading, Spacer, Link } from "native-base";

export default function Footer({ navigation }) {
  return (
    <>
      <Box width="100%" height="50" bgColor="white" />
      <Box width="100%" height="300" bgColor="primary.500">
        <HStack width="100%" justifyContent="space-evenly" my="10">
          <VStack alignContent="space-between">
            <Heading size="md" color="white">
              Links
            </Heading>
            <Link href="https://licensecare.dk/om-os/">
              <Text bold color="rgba(255,255,255,0.79)">
                Om os
              </Text>
            </Link>
            <Link href="https://licensecare.dk/kontakt/">
              <Text bold color="rgba(255,255,255,0.79)">
                Kontakt os
              </Text>
            </Link>
            <Link href="https://licensecare.dk/handelsbetingelser/">
              <Text bold color="rgba(255,255,255,0.79)">
                Handelsbetingelser
              </Text>
            </Link>
            <Text> </Text>
            <Heading size="sm" color="white">
              Indbetalinger til LicenseCare ApS:
            </Heading>

            <Text bold color="white">
              Handelsbanken
            </Text>
            <Text bold color="white">
              Reg:0896
            </Text>
            <Text bold color="white">
              Konto nr: 1070989
            </Text>
          </VStack>
          <VStack>
            <Heading size="md" color="white">
              Viden
            </Heading>
            <Link href="https://licensecare.dk/abonnement/">
              <Text bold color="rgba(255,255,255,0.79)">
                Abonnement
              </Text>
            </Link>
            <Link href="https://licensecare.dk/cookies-og-persondatapolitik/">
              <Text bold color="rgba(255,255,255,0.79)">
                Cookies- og persondatalov
              </Text>
            </Link>
          </VStack>
          <VStack>
            <Heading size="md" color="white">
              LicenseCare ApS
            </Heading>
            <Spacer />
            <Text bold color="white">
              CVR: 43285718
            </Text>
            <Spacer />

            <Text bold color="white">
              Telefon 22 66 69 69
            </Text>
            <Spacer />

            <Text bold color="white">
              Gammel Gugvej 21
            </Text>
            <Spacer />
            <Text bold color="white">
              9000 Aalborg
            </Text>
            <Spacer />
          </VStack>
        </HStack>
      </Box>
    </>
  );
}

import React from "react";
import { HStack, Heading, Spinner as NBSpinner } from "native-base";

export default function Spinner() {
  return (
    <HStack
      justifyContent="center"
      ali
      alignContent="center"
      alignItems="center"
      flex="1"
    >
      <NBSpinner color="primary.500" accessibilityLabel="Loading" />
      <Heading color="primary.500" fontSize="md">
        Loading
      </Heading>
    </HStack>
  );
}

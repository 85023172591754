import type { CellPlugin } from "@react-page/editor";
import React from "react";

const ButtonPlugin: CellPlugin<{
  text: string;
  link: string;
}> = {
  Renderer: ({ data }) => (
    <div
      style={{
        margin: "0 auto",
        backgroundColor: "#0BA78F",
        alignContent: "center",
        padding: 5,
        borderRadius: 10,
      }}
    >
      <center>
        <a
          href={data.link}
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "normal",
            textDecoration: "none",
            textAlign: "center",
            color: "white",
            fontSize: 20,
          }}
        >
          <b>{data.text}</b>
        </a>
      </center>
    </div>
  ),
  id: "button-plugin",
  title: "Button plugin",
  description: "Button plugin",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      properties: {
        text: {
          type: "string",
          default: "Læs mere...",
        },
        link: {
          type: "string",
        },
      },
      required: ["text", "link"],
    },
  },
};
export default ButtonPlugin;

import React, { useContext, useEffect, useState } from "react";
import {
  Heading,
  Column,
  Text,
  Spacer,
  HStack,
  Badge,
  Button,
} from "native-base";
import { Link } from "@react-navigation/native";
import { FirebaseContext } from "../context/FirebaseContext";
import { onSnapshot, collection, query } from "firebase/firestore";
import Footer from "../components/Footer";

export default function PagesScreen() {
  const [pages, setPages] = useState([]);
  const firebaseContext = useContext(FirebaseContext);
  const { db } = firebaseContext;

  const getPages = async () => {
    try {
      const queryConstraints = [];
      const q = query(collection(db, "pages"), ...queryConstraints);
      const unsub = onSnapshot(q, (qSnap) => {
        const _pages = [];
        qSnap.forEach((doc) => {
          let p = doc.data();
          p.pid = doc.id;
          if (!p.deleted) _pages.push(p);
        });
        setPages(_pages);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getPages();
  }, [getPages]);
  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Sider</Heading>
      <Link to={{ screen: "Editor" }}>
        <Button bg="primary.500">Opret ny side</Button>
      </Link>
      {pages.map((page, i) => (
        <HStack
          bg="gray.200"
          px="2"
          rounded="lg"
          alignItems="center"
          justifyContent="space-between"
          w="80%"
          key={i}
        >
          <Text bold>{page.title}</Text>
          <Spacer />
          <Badge>{page.published ? "Udgivet" : "Kladde"}</Badge>
          <Link to={`/editor?pageId=${page.pid}`}>
            <Button m="2" bgColor="primary.500">
              <Text color="white">Rediger</Text>
            </Button>
          </Link>
        </HStack>
      ))}
      <Footer />
    </Column>
  );
}

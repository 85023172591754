import React, { useState, useContext, useCallback, useEffect } from "react";

import Editor from "@react-page/editor";
import { cellPlugins } from "../plugins/cellPlugins";
import { Heading, Column } from "native-base";
import { FirebaseContext } from "../context/FirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import Footer from "../components/Footer";
import { debounce } from "../utils/tools";

export default function MemberScreen({ navigation }) {
  const firebaseContext = useContext(FirebaseContext);
  const { db } = firebaseContext;
  const [pageContent, setPageContent] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  const getPage = useCallback(async (pId) => {
    let pSnap;
    try {
      pSnap = await getDoc(doc(db, "pages", pId));
    } catch (error) {
      alert(error);
    }
    if (pSnap.exists()) {
      let page = pSnap.data();
      if (page.published) {
        // TODO: setPageTitle(page?.title);
        setPageContent(page?.content);
      } else {
        console.log("page not published");
        setPageTitle("Not Authorized 401");
      }
    } else {
      setPageTitle("Not Found 404");
    }
  }, []);

  useEffect(() => {
    getPage(__DEV__ ? "lGiT6gNL9j2Ki1yyR7J2" : "vGrNzd0S7UoGgC7sGWt9");
  }, [getPage]);

  return (
    <Column>
      <Column alignItems="center" mx={dimensions.width < 1025 ? 0 : 40}>
        <Heading size="lg">{pageTitle}</Heading>
        <Editor cellPlugins={cellPlugins} value={pageContent} readOnly />
      </Column>
      <Footer />
    </Column>
  );
}

import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  Heading,
  Column,
  Text,
  Icon,
  HStack,
  FlatList,
  Box,
  Spacer,
  Button,
  VStack,
} from "native-base";
import { AuthContext } from "../context/AuthContext";
import { FirebaseContext } from "../context/FirebaseContext";
import {
  onSnapshot,
  query,
  collection,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { lcDate } from "../utils/tools";
import { Entypo } from "@expo/vector-icons";
import Footer from "../components/Footer";

export default function EnrolmentScreen() {
  const authContext = useContext(AuthContext);
  const firebaseContext = useContext(FirebaseContext);
  const [enrolment, setEnrolment] = useState([]);
  const { db } = firebaseContext;

  const toggleApprove = async (eid) => {
    // console.log("approving eid", eid, eid.approved);
    const _enrolment = enrolment.find((e) => (e.eid = eid));
    // console.log("_enrolment", _enrolment);

    await setDoc(
      doc(
        db,
        "companies",
        authContext.userData.companyId,
        "enrolment",
        _enrolment.cid,
        "enrolment",
        _enrolment.eid
      ),
      {
        approved: !_enrolment.approved,
        approveUpdated: serverTimestamp(),
      },
      { merge: true }
    );
  };
  const getCompanyEnrolment = useCallback(async () => {
    try {
      const unsub = onSnapshot(
        query(
          collection(
            db,
            "companies",
            authContext.userData.companyId,
            "enrolment"
          )
        ),
        (coursesSnap) => {
          for (const courseDoc of coursesSnap.docs) {
            let course = courseDoc.data();
            course.cid = courseDoc.id;
            onSnapshot(
              query(collection(courseDoc.ref, "enrolment")),
              (enrolmentSnap) => {
                for (const enrolmentDoc of enrolmentSnap.docs) {
                  let _enrolment = enrolmentDoc.data();
                  _enrolment.eid = enrolmentDoc.id;
                  const eObj = { ...course, ..._enrolment };
                  // console.log("eObj", eObj);

                  setEnrolment([...enrolment, eObj]);
                }
              }
            ); //unsub not stored
          }
        }
      );
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getCompanyEnrolment();
  }, [getCompanyEnrolment]);

  // console.log("data", enrolment);
  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Kursustilmeldinger</Heading>
      <FlatList
        data={enrolment}
        renderItem={({ item }) => (
          <Box
            direction="row"
            w="100%"
            bg="gray.200"
            p="1"
            m="1"
            alignItems="center"
            rounded="xl"
          >
            <HStack alignItems="center" p="1" m="1" space="2">
              {item.approved ? (
                <Icon
                  as={Entypo}
                  name="check"
                  size="5"
                  mt="0.5"
                  color="success.500"
                />
              ) : (
                <Icon
                  as={Entypo}
                  name="warning"
                  size="5"
                  mt="0.5"
                  color="danger.500"
                />
              )}
              <VStack>
                <Text>{item.course_name}</Text>
                <Spacer />
                <Text>Dato: {lcDate(item.course_begin?.toDate())}</Text>
                <Spacer />
                <Text>
                  {item.first_name} {item.last_name}
                </Text>
              </VStack>
              {item.approved ? (
                <Button onPress={() => toggleApprove(item.eid)}>afvis</Button>
              ) : (
                <Button onPress={() => toggleApprove(item.eid)}>godkend</Button>
              )}
            </HStack>
          </Box>
        )}
        keyExtractor={(item) => item.eid}
        ListEmptyComponent={
          <HStack space={2}>
            <Icon
              as={Entypo}
              name="warning"
              size="5"
              mt="0.5"
              color="danger.500"
            />
            <Text bold>Ingen tilmeldinger</Text>
          </HStack>
        }
      />
      <Footer />
    </Column>
  );
}

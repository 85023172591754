import React, { useContext, useRef, useState } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  FormControl,
  Input,
  Text,
  Select,
  Checkbox,
  Link,
} from "native-base";
// import { Link } from "@react-navigation/native";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext, readUserData } from "../context/AuthContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import {
  collection,
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { ImageBackground } from "react-native";
import Footer from "../components/Footer";

export default function SignUpScreen({ navigation }) {
  const [cvrSearching, setCvrSearching] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const toggleShowPass = () => setShowPass(!showPass);
  const toggleShowPass2 = () => setShowPass2(!showPass2);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const ref = useRef(null);

  const onSubmit = async (data) => {
    console.log("submiting with ", data);
    try {
      const { auth, db } = firebaseContext;
      createUserWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          console.log("Signed in user", user);

          const companyDocRef = await addDoc(collection(db, "companies"), {
            name: data.company_name,
            cvr: data.cvr,
            admin_user: user.uid,
            address: data.address,
            zip: data.zip,
            city: data.city,
            region: data.region,
            invoice_mail: data.invoice_mail,
            phone: data.phone,
            active: false,
            userCount: 0,
            inviteCount: 0,
            created: serverTimestamp(),
          });

          await setDoc(
            doc(db, "companies", companyDocRef.id, "users", user.uid),
            {
              email: user.email,
              first_name: data.first_name,
              last_name: data.last_name,
              company_admin: true,
              region: data.region,
              created: serverTimestamp(),
            }
          );
          await setDoc(doc(db, "users", user.uid), {
            companyId: companyDocRef.id,
            company_admin: true,
            created: serverTimestamp(),
            region: data.region,
            // email: user.email,
            // first_name: data.first_name,
            // last_name: data.last_name,
          });
          console.log("signup done");

          const { setUserData } = authContext;
          setUserData(await readUserData(db, user.uid));
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.warn("error", errorCode, errorMessage);
          if (errorCode === "auth/email-already-in-use") {
            alert(
              "Der er allerede en bruger oprettet med den email i LicenseCare. Prøv i stedet at logge ind."
            );
          } else {
            alert(errorMessage);
          }
        });
    } catch (error) {
      alert(
        "Oprettelse af firma fejlede, ring venligst til Mikkel på +45 22 66 69 69",
        error
      );
    }
  };

  const initialValues = __DEV__
    ? {
        email: "x@kochdigital.dk",
        password: "Hemmelig1",
        passwordVerify: "Hemmelig1",
        first_name: "Navn",
        last_name: "Navnesen",
        company_name: "Firma ApS",
        cvr: "38326694",
        notes: "",
        city: "Byen",
        phone: "12341234",
        address: "Byvejen 12",
        zip: "2300",
        region: "nord",
        invoice_mail: "faktura@firma.dk",
      }
    : {
        email: "",
        password: "",
        passwordVerify: "",
        first_name: "",
        last_name: "",
        company_name: " ApS",
        cvr: "",
        notes: "",
        city: "",
        phone: "",
        address: "",
        zip: "",
        region: "nord",
        invoice_mail: "",
      };

  const lookupCompany = async () => {
    setCvrSearching(true);
    const { app } = firebaseContext;

    const functions = getFunctions(app, "europe-west3");
    if (__DEV__) {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }

    const { cvr } = ref.current.values;
    // console.log("lookupCompany", cvr);
    if (cvr.length === 8) {
      // console.log("found a company");
      const signupCVRlookup = httpsCallable(
        functions,
        "cvrLookup-signupCVRlookup"
      );
      signupCVRlookup({ cvr }).then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        ref.current.setFieldValue(
          "company_name",
          data?.virksomhedMetadata?.nyesteNavn?.navn
            ? data?.virksomhedMetadata?.nyesteNavn?.navn
            : ""
        );
        ref.current.setFieldValue(
          "address",
          `${
            data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.vejnavn
              ? data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.vejnavn
              : ""
          } ${
            data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.husnummerFra
              ? data?.virksomhedMetadata?.nyesteBeliggenhedsadresse
                  ?.husnummerFra
              : ""
          }${
            data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.bogstavFra
              ? data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.bogstavFra
              : ""
          }`
        );
        ref.current.setFieldValue(
          "zip",
          data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.postnummer
            ? data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.postnummer
            : ""
        );
        ref.current.setFieldValue(
          "city",
          data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.postdistrikt
            ? data?.virksomhedMetadata?.nyesteBeliggenhedsadresse?.postdistrikt
            : ""
        );
        ref.current.setFieldValue(
          "phone",
          data?.telefonNummer[0]?.kontaktoplysning
            ? data?.telefonNummer[0]?.kontaktoplysning
            : ""
        );
        ref.current.setFieldValue(
          "invoice_mail",
          data?.elektroniskPost[0]?.kontaktoplysning
            ? data?.elektroniskPost[0]?.kontaktoplysning
            : ""
        );
        setCvrSearching(false);
      });
      //TODO: maybe also lookup on company name
    }
  };

  return (
    <ImageBackground
      source={{
        uri: require("../assets/bg4.jpg"),
      }}
      // style={{ flex: 1 }}
      alt="Alternate Text"
    >
      <Center bg="#00000055">
        <Column>
          <Heading color="white" size="lg">
            Opret Firmaaftale
          </Heading>
          <Text color="white">
            Her kan du oprette firmaaftalen og få adgang til LicenseCare
            abonnement.
          </Text>

          <Formik
            innerRef={ref}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Ikke en email adresse")
                .required("Krævet"),
              password: Yup.string()
                .required("Password mangler.")
                .min(8, "Password er for kort - should be 8 chars minimum.")
                .matches(
                  /[a-zA-Z0-9]/,
                  "Password can only contain Latin letters and numbers."
                ),
              passwordVerify: Yup.string()
                .required("Password mangler.")
                .min(8, "Password er for kort - should be 8 chars minimum.")
                .matches(
                  /[a-zA-Z0-9]/,
                  "Password can only contain Latin letters and numbers."
                )
                .oneOf([Yup.ref("password"), null], "Passwords er ikke ens."),
              first_name: Yup.string().required("Krævet"),
              last_name: Yup.string().required("Krævet"),
              company_name: Yup.string().required("Krævet"),
              cvr: Yup.string()
                .min(8, "CVR er for kort")
                .max(8, "CVR er for langt")
                .required("Krævet"),
              notes: Yup.string(),
              city: Yup.string().required("Krævet"),
              phone: Yup.string().required("Krævet"),
              address: Yup.string().required("Krævet"),
              zip: Yup.string()
                .min(4, "postnummer er for kort - skal være 4 cifre")
                .max(4, "postnummer er for langt - skal være 4 cifre")
                .matches(/[0-9]/, "Postnummer skal være 4 talcifre.")
                .required("Postnummer mangler."),
              region: Yup.string().required("Krævet"),
              invoice_mail: Yup.string()
                .email("Ikke en email adresse")
                .required("Krævet"),
            })}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              isValid,
            }) => (
              <>
                <FormControl isRequired isInvalid={"first_name" in errors}>
                  <FormControl.Label
                    _text={{
                      color: "white",
                    }}
                  >
                    Fornavn
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("first_name")}
                    placeholder="Fornavn"
                    onChangeText={handleChange("first_name")}
                    value={values.first_name}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="givenName"
                  />
                  <FormControl.ErrorMessage>
                    {errors.first_name}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"last_name" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Efternavn
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("last_name")}
                    placeholder="Efternavn"
                    onChangeText={handleChange("last_name")}
                    value={values.last_name}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="familyName"
                  />
                  <FormControl.ErrorMessage>
                    {errors.last_name}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"cvr" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    CVR nr.
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("cvr")}
                    placeholder="32125424"
                    onChangeText={handleChange("cvr")}
                    value={values.cvr}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    keyboardType="number-pad"
                    InputRightElement={
                      <Button
                        size="xs"
                        rounded="none"
                        w="1/6"
                        h="full"
                        onPress={lookupCompany}
                        isDisabled={"cvr" in errors}
                        bgColor="primary.500"
                      >
                        {cvrSearching ? "Henter..." : "Hent firma info"}
                      </Button>
                    }
                  />
                  <FormControl.ErrorMessage>
                    {errors.cvr}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"company_name" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Firma
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("company_name")}
                    placeholder="Firmanavn ApS"
                    onChangeText={handleChange("company_name")}
                    value={values.company_name}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="organizationName"
                  />
                  <FormControl.ErrorMessage>
                    {errors.company_name}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"address" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Adresse
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("address")}
                    placeholder="Nygade 12"
                    onChangeText={handleChange("address")}
                    value={values.address}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="streetAddressLine1"
                  />
                  <FormControl.ErrorMessage>
                    {errors.address}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"zip" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Postnummer
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("zip")}
                    placeholder="9000"
                    onChangeText={handleChange("zip")}
                    value={values.zip}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="postalCode"
                    keyboardType="number-pad"
                  />
                  <FormControl.ErrorMessage>
                    {errors.zip}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"city" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    By
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("city")}
                    placeholder="Gammelby"
                    onChangeText={handleChange("city")}
                    value={values.city}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="addressCity"
                  />
                  <FormControl.ErrorMessage>
                    {errors.city}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"region" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Region
                  </FormControl.Label>
                  <Select
                    defaultValue={values.region}
                    accessibilityLabel="Vælg region"
                    placeholder="Vælg region"
                    mt={1}
                    value={values.region}
                    onValueChange={handleChange("region")}
                    onBlur={handleBlur("region")}
                    style={{ display: "block" }}
                    bgColor="white"
                  >
                    <Select.Item label="Nordjylland" value="nord" />
                    <Select.Item label="Midtjylland" value="midt" />
                    <Select.Item label="Syddanmark" value="syd" />
                    <Select.Item label="Hovedstaden" value="hovedstaden" />
                    <Select.Item label="Sjælland" value="sjælland" />
                  </Select>
                </FormControl>

                <FormControl isRequired isInvalid={"phone" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Telefonnummer
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("phone")}
                    placeholder="80123456"
                    onChangeText={handleChange("phone")}
                    value={values.phone}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="telephoneNumber"
                    keyboardType="phone-pad"
                  />
                  <FormControl.ErrorMessage>
                    {errors.phone}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"invoice_mail" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Faktura email
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("invoice_mail")}
                    placeholder="bogholderi@firma.dk"
                    onChangeText={handleChange("invoice_mail")}
                    value={values.invoice_mail}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="emailAddress"
                    keyboardType="email-address"
                  />
                  <FormControl.ErrorMessage>
                    {errors.invoice_mail}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"email" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Din email
                  </FormControl.Label>
                  <Input
                    onBlur={handleBlur("email")}
                    placeholder="navn@firma.dk"
                    onChangeText={handleChange("email")}
                    value={values.email}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="emailAddress"
                    keyboardType="email-address"
                  />
                  <FormControl.ErrorMessage>
                    {errors.email}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"password" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Password
                  </FormControl.Label>
                  <Input
                    type={showPass ? "text" : "password"}
                    InputRightElement={
                      <Button
                        size="xs"
                        rounded="none"
                        w="1/6"
                        h="full"
                        onPress={toggleShowPass}
                        bgColor="primary.500"
                      >
                        {showPass ? "Gem" : "Vis"}
                      </Button>
                    }
                    onBlur={handleBlur("password")}
                    placeholder="<hemmelig kode>"
                    onChangeText={handleChange("password")}
                    value={values.password}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="newPassword"
                  />
                  <FormControl.ErrorMessage>
                    {errors.password}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={"passwordVerify" in errors}>
                  <FormControl.Label _text={{ color: "white" }}>
                    Gentag Password
                  </FormControl.Label>
                  <Input
                    type={showPass2 ? "text" : "password"}
                    InputRightElement={
                      <Button
                        size="xs"
                        rounded="none"
                        w="1/6"
                        h="full"
                        onPress={toggleShowPass2}
                        bgColor="primary.500"
                      >
                        {showPass2 ? "Gem" : "Vis"}
                      </Button>
                    }
                    onBlur={handleBlur("passwordVerify")}
                    placeholder="<samme hemmelige kode>"
                    onChangeText={handleChange("passwordVerify")}
                    value={values.passwordVerify}
                    bgColor="white"
                    autoCorrect={false}
                    spellCheck={false}
                    textContentType="newPassword"
                  />
                  <FormControl.ErrorMessage>
                    {errors.passwordVerify}
                  </FormControl.ErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={acceptTerms === false}>
                  <Checkbox.Group
                    onChange={() => {
                      setAcceptTerms(!acceptTerms);
                    }}
                    accessibilityLabel="Acceptér handelsbetingelser"
                  >
                    <Checkbox value="terms">
                      <Text color="white">
                        Acceptér
                        <Link
                          isExternal
                          href="https://licensecare.dk/handelsbetingelser"
                        >
                          <Button variant="link">
                            <Text color="primary.500">handelsbetingelser</Text>
                          </Button>
                        </Link>
                      </Text>
                    </Checkbox>
                  </Checkbox.Group>
                  <FormControl.ErrorMessage>
                    {errors.acceptTerms}
                  </FormControl.ErrorMessage>
                </FormControl>

                <Button
                  isDisabled={!isValid || !acceptTerms}
                  onPress={handleSubmit}
                  bgColor="primary.500"
                  mb="100"
                >
                  <Text color="white">Opret</Text>
                </Button>
              </>
            )}
          </Formik>
        </Column>
      </Center>
      <Footer />
    </ImageBackground>
  );
}

import React, { useState, useRef } from "react";
import { Center, AlertDialog, Button } from "native-base";

export default function ConfirmDeleteDialog(props) {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = useRef();

  const onConfirmedDelete = () => {
    // console.log("onConfirmedDelete");
    onClose();
    console.log("props", props);
    props?.onConfirmDelete();
  };

  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        // motionPreset={"fade"}
      >
        <AlertDialog.Content>
          <AlertDialog.Header fontSize="lg" fontWeight="bold">
            {props.header}
          </AlertDialog.Header>
          <AlertDialog.Body>
            Er du sikker? Du kan ikke omgøre denne handling.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button ref={cancelRef} onPress={onClose}>
              Fortryd
            </Button>
            <Button colorScheme="danger" onPress={onConfirmedDelete} ml={3}>
              Slet
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <Button colorScheme="danger" onPress={() => setIsOpen(!isOpen)}>
        Slet
      </Button>
    </Center>
  );
}

import { Heading, Column, Text, Flex, Center, Link } from "native-base";
import { Link as NavLink } from "@react-navigation/native";
import Footer from "../components/Footer";
export default function AdminScreen({ navigation }) {
  const urlBase = __DEV__
    ? "http://localhost:5001/kortklub-f4404/europe-west3"
    : "https://europe-west3-kortklub-f4404.cloudfunctions.net";
  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Administrator Værktøjer</Heading>
      <Flex w="md" borderColor="primary.500" borderWidth="2" bg="white" p="2">
        <Center>
          <Link href={`${urlBase}/bookkeeping-debtorsCSV`}>
            <Text underline>Udlæs debitorer</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-creditorsTeachersCSV`}>
            <Text underline>Udlæs kreditorer - undervisere</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-creditorsLocationsCSV`}>
            <Text underline>Udlæs kreditorer - steder</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-subscriberIncomeCSV`}>
            <Text underline>Udlæs til faktura for abonnementer</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-coursesIncomeCSV`}>
            <Text underline>Udlæs til faktura for kurser</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-disqualificationExpenseCSV`}>
            <Text underline>Udlæs til udbetalinger for frakendelser</Text>
          </Link>
          <Link href={`${urlBase}/bookkeeping-courseExpenseCSV`}>
            <Text underline>Udlæs til honorar for kurser</Text>
          </Link>
          {/* <Link to={{ screen: "Admin" }}>
            <Text underline>Email-blæseren</Text>
          </Link> */}
        </Center>
      </Flex>
      <Footer />
    </Column>
  );
}

import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  Center,
  Heading,
  Column,
  Button,
  Box,
  FormControl,
  Input,
  Select,
  Text,
  Checkbox,
  TextArea,
} from "native-base";
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from "../context/FirebaseContext";
import { AuthContext } from "../context/AuthContext";
import {
  onSnapshot,
  collection,
  doc,
  query,
  addDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import LocationsList from "../components/LocationsList";
import Footer from "../components/Footer";

export default function LocationsScreen({ navigation }) {
  const firebaseContext = useContext(FirebaseContext);
  const authContext = useContext(AuthContext);
  const [locations, setLocations] = useState();
  const [showEditor, setShowEditor] = useState(false);
  const toggleShowEditor = () => setShowEditor(!showEditor);
  const [editLoc, setEditLoc] = useState(null);
  const [editLocTypes, setEditLocTypes] = useState(["drivers_license"]);
  const { db } = firebaseContext;
  const { userData } = authContext;

  const getLocations = useCallback(async () => {
    try {
      const q = query(collection(db, "locations"));
      const unsub = onSnapshot(q, (qSnap) => {
        const _locations = [];
        qSnap.forEach((doc) => {
          let l = doc.data();
          l.lid = doc.id;
          _locations.push(l);
        });
        setLocations(_locations);
        console.log("Locations: ", _locations);
      });
      return () => {
        unsub();
      };
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const storeLocation = async (data) => {
    console.log("submiting with ", data, editLocTypes);

    if (data.lid) {
      await setDoc(
        doc(db, "locations", data.lid),
        {
          name: data.name,
          cvr: data.cvr,
          address: data.address,
          zip: data.zip,
          city: data.city,
          phone: data.phone,
          region: data.region,
          type: editLocTypes,
          updated: serverTimestamp(),
          note: data.note,
        },
        { merge: true }
      );
      setEditLoc(null);
    } else {
      await addDoc(collection(db, "locations"), {
        name: data.name,
        cvr: data.cvr,
        address: data.address,
        zip: data.zip,
        city: data.city,
        phone: data.phone,
        region: data.region,
        type: editLocTypes,
        created: serverTimestamp(),
        note: data.note,
      });
    }
    toggleShowEditor();
  };

  const editLocation = (item) => {
    setEditLoc(item);
    // console.log("edit", item);
    toggleShowEditor();
  };

  const initialValues = __DEV__
    ? {
        name: "Trafikskolen drøn",
        cvr: "12121212",
        address: "Høvej 1",
        zip: "9000",
        phone: "28287878",
        city: "Lilleby",
        region: "nord",
        type: "road_safety_center",
        note: "ny asfalt i 2021",
      }
    : {
        name: "",
        cvr: "",
        address: "",
        zip: "",
        phone: "",
        city: "",
        region: "nord",
        type: "",
        note: "",
      };

  return (
    <Column space={5} alignItems="center">
      <Heading size="lg">Undervisningssteder</Heading>
      {userData?.super_admin && (
        <Center>
          {showEditor ? (
            <Formik
              initialValues={initialValues}
              onSubmit={storeLocation}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Krævet"),
                cvr: Yup.string()
                  .min(8, "CVR er for kort")
                  .max(8, "CVR er for langt")
                  .required("Krævet"),
                address: Yup.string().required("Krævet"),
                zip: Yup.string()
                  .min(4, "postnummer er for kort - skal være 4 cifre")
                  .max(4, "postnummer er for langt - skal være 4 cifre")
                  .matches(/[0-9]/, "Postnummer skal være 4 talcifre.")
                  .required("Postnummer mangler."),
                city: Yup.string().required("Krævet"),
                phone: Yup.string().required("Krævet"),
                region: Yup.string().required("Krævet"),
                note: Yup.string(),
              })}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
                errors,
                isValid,
              }) => {
                useEffect(() => {
                  // console.log("editLoc", editLoc);
                  const fields = [
                    "name",
                    "cvr",
                    "address",
                    "zip",
                    "phone",
                    "city",
                    "region",
                    "type",
                    "lid",
                    "note",
                  ];
                  if (editLoc) {
                    fields.forEach((field) =>
                      setFieldValue(field, editLoc[field], false)
                    );
                    setEditLocTypes(editLoc.type);
                  } else {
                    // fields.forEach((field) => setFieldValue(field, "", false));
                    setEditLocTypes(["drivers_license"]);
                  }
                }, []);
                return (
                  <Box shadow={1} p={4}>
                    <Heading size="sm">
                      {editLoc ? <>Rediger sted</> : <>Opret nyt sted</>}
                    </Heading>
                    <FormControl isRequired isInvalid={"name" in errors}>
                      <FormControl.Label>Navn</FormControl.Label>
                      <Input
                        onBlur={handleBlur("name")}
                        placeholder="Stedets navn"
                        onChangeText={handleChange("name")}
                        value={values.name}
                      />
                      <FormControl.ErrorMessage>
                        {errors.name}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"cvr" in errors}>
                      <FormControl.Label>CVR</FormControl.Label>
                      <Input
                        onBlur={handleBlur("cvr")}
                        placeholder="43285718"
                        onChangeText={handleChange("cvr")}
                        value={values.cvr}
                      />
                      <FormControl.ErrorMessage>
                        {errors.cvr}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"address" in errors}>
                      <FormControl.Label>Adresse</FormControl.Label>
                      <Input
                        onBlur={handleBlur("address")}
                        placeholder="Nygade 12"
                        onChangeText={handleChange("address")}
                        value={values.address}
                      />
                      <FormControl.ErrorMessage>
                        {errors.address}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"zip" in errors}>
                      <FormControl.Label>Postnummer</FormControl.Label>
                      <Input
                        onBlur={handleBlur("zip")}
                        placeholder="9000"
                        onChangeText={handleChange("zip")}
                        value={values.zip}
                      />
                      <FormControl.ErrorMessage>
                        {errors.zip}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"city" in errors}>
                      <FormControl.Label>By</FormControl.Label>
                      <Input
                        onBlur={handleBlur("city")}
                        placeholder="Gammelby"
                        onChangeText={handleChange("city")}
                        value={values.city}
                      />
                      <FormControl.ErrorMessage>
                        {errors.city}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"region" in errors}>
                      <FormControl.Label>Region</FormControl.Label>
                      <Select
                        defaultValue={values.region}
                        accessibilityLabel="Vælg region"
                        placeholder="Vælg region"
                        mt={1}
                        selectedValue={values.region}
                        onValueChange={handleChange("region")}
                        onBlur={handleBlur("region")}
                        style={{ display: "block" }}
                      >
                        <Select.Item label="Nordjylland" value="nord" />
                        <Select.Item label="Midtjylland" value="midt" />
                        <Select.Item label="Syddanmark" value="syd" />
                        <Select.Item label="Hovedstaden" value="hovedstaden" />
                        <Select.Item label="Sjælland" value="sjælland" />
                      </Select>
                    </FormControl>

                    <FormControl isRequired isInvalid={"phone" in errors}>
                      <FormControl.Label>Telefonnummer</FormControl.Label>
                      <Input
                        onBlur={handleBlur("phone")}
                        placeholder="80123456"
                        onChangeText={handleChange("phone")}
                        value={values.phone}
                      />
                      <FormControl.ErrorMessage>
                        {errors.phone}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={"type" in errors}>
                      <FormControl.Label>Type faciliteter</FormControl.Label>
                      <Checkbox.Group
                        // defaultValue={types}
                        onChange={(values) => {
                          setEditLocTypes(values || []);
                        }}
                        accessibilityLabel="vælg faciliteter"
                        value={editLocTypes}
                      >
                        <Checkbox
                          value="drivers_license"
                          accessibilityLabel="Kørekort undervisning"
                        >
                          Kørekort undervisning
                        </Checkbox>
                        <Checkbox
                          value="first_aid"
                          accessibilityLabel="Førstehjælp"
                        >
                          Førstehjælp
                        </Checkbox>
                        <Checkbox
                          value="green"
                          accessibilityLabel="Grønt kørekursus"
                        >
                          Grønt kørekursus
                        </Checkbox>
                        <Checkbox
                          value="ice_driving"
                          accessibilityLabel="Glatføre"
                        >
                          Glatføre
                        </Checkbox>
                        <Checkbox
                          value="driving_technique"
                          accessibilityLabel="Køreteknisk kursus"
                        >
                          Køreteknik
                        </Checkbox>
                      </Checkbox.Group>
                    </FormControl>

                    <FormControl isInvalid={"note" in errors}>
                      <FormControl.Label>Bemærk</FormControl.Label>
                      <TextArea
                        onBlur={handleBlur("note")}
                        placeholder="Bemærkninger til stedet..."
                        onChange={handleChange("note")}
                        value={values.note}
                      />
                      <FormControl.ErrorMessage>
                        {errors.note}
                      </FormControl.ErrorMessage>
                    </FormControl>

                    <Button
                      isDisabled={!isValid || editLocTypes.length == 0}
                      onPress={handleSubmit}
                      bgColor="primary.500"
                    >
                      {editLoc ? (
                        <Text color="white">Gem sted</Text>
                      ) : (
                        <Text color="white">Opret sted</Text>
                      )}
                    </Button>
                    <Button variant="outline" onPress={toggleShowEditor}>
                      <Text>Afbryd</Text>
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          ) : (
            <Button
              bgColor="primary.500"
              key="createBtn"
              onPress={() => {
                setEditLoc(null);
                toggleShowEditor();
              }}
            >
              Opret nyt sted
            </Button>
          )}
        </Center>
      )}
      <LocationsList onEdit={editLocation} />
      <Footer />
    </Column>
  );
}

import { Heading, VStack } from "native-base";
import CompaniesList from "../components/CompaniesList";
import Footer from "../components/Footer";

export default function CompaniesScreen({ navigation }) {
  return (
    <VStack space={5} alignItems="center" width="90%">
      <Heading size="lg">Virksomheder</Heading>
      <CompaniesList />
      <Footer />
    </VStack>
  );
}
